import { Button, Icon } from '@chakra-ui/react'

import { mdiCloudUploadOutline, mdiUndoVariant } from '@mdi/js'
import { useContext } from 'react'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { VersionContext, saveVersionData } from '../../../contexts/VersionContext.js'
import { ConfirmationContext } from '../../providers/ConfirmationProvider.js'
import useStylesUpToDateConfirmationAction from '../../../hooks/useStylesUpToDateConfirmationAction.js'

export function VersionStatusButton() {
  const [{ editor }] = useContext(EditorContext)
  const [{ version, snapshotStatus }] = useContext(VersionContext)
  const { setConfirm } = useContext(ConfirmationContext)
  const handleStage = useStylesUpToDateConfirmationAction('stage', version, editor)
  const handlePublish = useStylesUpToDateConfirmationAction('publish', version, editor)

  let handleClick: () => void,
    leftIcon,
    text,
    isDisabled = !!snapshotStatus
  if (version.isDeleted()) {
    handleClick = () => version.restore()
    leftIcon = (
      <Icon>
        <path d={mdiUndoVariant} />
      </Icon>
    )
    text = version.getLastPushed() ? 'Unarchive' : 'Undelete'
  } else {
    if (version.needsStaging() && !version.forkOriginId) {
      const staged = version.getCurrent('staged')
      handleClick = handleStage
      leftIcon = (
        <Icon boxSize='icon-md'>
          <path d={mdiCloudUploadOutline} />
        </Icon>
      )
      text = staged ? 'Restage' : 'Stage'
    } else if (version.needsPublishing()) {
      const published = version.getCurrent('published')
      handleClick = () => {
        setConfirm({
          action: () => {
            // setTimeout is needed here cos we are opening a confirmation dialog from another confirmation dialog
            setTimeout(() => {
              handlePublish()
            })
          },
          title: 'Publishing new revision',
          button: published ? 'Republish' : 'Publish',
          body: `Click ${
            published ? 'Republish' : 'Publish'
          } to make this updated version available on the live website`
        })
      }
      leftIcon = (
        <Icon boxSize='icon-md'>
          <path d={mdiCloudUploadOutline} />
        </Icon>
      )
      text = published ? 'Republish' : 'Publish'
    } else {
      isDisabled = true
      leftIcon = null
      text = 'Up to date'
    }
  }
  return (
    <Button
      className='status-button'
      px={2}
      justifyContent={leftIcon ? 'flex-start' : 'center'}
      isDisabled={isDisabled}
      onClick={() => {
        handleClick()
      }}
      variant='primary'
      size='xs'
      leftIcon={leftIcon}
    >
      {text}
    </Button>
  )
}

export default VersionStatusButton
