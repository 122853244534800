import { Box, Button, Icon, Spinner } from '@chakra-ui/react'
import { mdiCheck } from '@mdi/js'
import { useContext } from 'react'
import { EditorContext } from '../../../contexts/EditorContext.js'

export function EditorUISave(
  props?: Partial<{ onSave: (...args: any[]) => void } & Pick<EditorContext, 'savingStatus'>>
) {
  const [editorContext] = useContext(EditorContext)
  const { onSave, savingStatus } = { ...editorContext, onSave: () => editorContext.editor.save(), ...props }
  return (
    <Button
      id='save-button'
      width={102}
      variant='secondary'
      leftIcon={
        savingStatus == 'saving' ? (
          <Box boxSize='24px' display='flex'>
            <Spinner boxSize='18px' speed='1.5s' alignSelf={'center'} justifySelf='center' />
          </Box>
        ) : savingStatus == 'waiting' ? null : (
          <Icon boxSize='icon-lg'>
            <path d={mdiCheck} />
          </Icon>
        )
      }
      justifyContent={savingStatus == 'waiting' ? 'center' : 'flex-start'}
      onClick={onSave}
      isDisabled={savingStatus != 'waiting'}
    >
      {savingStatus == 'synchronized' ? 'Saved' : savingStatus == 'saving' ? 'Saving' : 'Save'}
    </Button>
  )
}
