import type * as CK from '@sitecore-feaas/ckeditor5'
import { ComponentModel, Style, StylesheetModel } from '@sitecore-feaas/sdk'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export function useEditorBatch(
  editor: CK.Editor,
  context: CK.ModelElement
): [
  callback: (callback: (writer: CK.ModelWriter) => any) => any,
  batch: CK.ModelBatch,
  startBatch: () => CK.ModelBatch,
  finishBatch: () => CK.ModelBatch
] {
  const batchRef = useRef<CK.ModelBatch>()
  // any changes done by some other thing should reset current batch
  useEffect(() => {
    if (!editor) return
    const onChange = (evt: any, batch: CK.ModelBatch) => {
      if (batch.isLocal && batch.isUndoable && batch !== batchRef.current) {
        batchRef.current = null
      }
    }

    editor.model.document.on('change', onChange)
    return () => {
      editor.model.document.off('change', onChange)
    }
  }, [])

  // changing context will reset batch
  useEffect(() => {
    batchRef.current = null
    return () => {
      batchRef.current = null
    }
  }, [context])

  const finishBatch = useCallback(() => {
    const batch = batchRef.current
    batchRef.current = null
    return batch
  }, [])
  const startBatch = useCallback(() => {
    return (batchRef.current ||= editor.model.createBatch())
  }, [editor])

  const runningBatch = useRef<CK.ModelBatch>(null)

  const onBatch = useCallback(
    (callback: (writer: CK.ModelWriter) => any) => {
      if (runningBatch.current) {
        editor.model.change(callback)
      } else {
        runningBatch.current = startBatch()
        editor.model.enqueueChange(startBatch(), callback)
        runningBatch.current = null
      }
    },
    [editor]
  )
  return [onBatch, batchRef.current, startBatch, finishBatch]
}

export function useEditorStyles(editor: CK.Editor, context: CK.ModelElement): Style.Rule[] {
  const [renders, setRenders] = useState(0)

  const customStyles = useMemo(() => editor?.styles.getCustomStyles(context) || [], [context?.root?.rootName, renders])

  useEffect(() => {
    if (!editor) return
    const onCSSUpdate = () => {
      setRenders((r) => r + 1)
    }
    editor.observerPlugin.onAttributeChange('cssText', onCSSUpdate)
    return () => {
      editor.observerPlugin.offAttributeChange('cssText', onCSSUpdate)
    }
  }, [editor])

  return customStyles
}

export function useEditorContextStyles(editor: CK.Editor, context: CK.ModelElement): Style.Rule[] {
  const customStyles = useEditorStyles(editor, context)
  const instanceId = Style.Context.getInstanceId(context)
  return useMemo(() => customStyles.filter((s) => s.details.instanceId == instanceId), [customStyles, context])
}
