import { Box, Button, Icon, Text } from '@chakra-ui/react'
import { mdiClose } from '@mdi/js'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { Style } from '@sitecore-feaas/sdk'
import { useMemo } from 'react'
import { getContextIcon } from '../../../utils/element.js'
import { findEqualPlacement } from '../../../utils/placement.js'
import { ChromeRefs, EditorChromeProps } from './ChromeContext.js'

export default function ChromePlaceToolbar({
  onPlacementStart,
  onPlacementHighlight,
  onPlacement,
  placed,
  context,
  editor,
  refs
}: EditorChromeProps & {
  refs: ChromeRefs
}) {
  const isDragging = placed?.root.is('rootElement')
  const isVersion = placed?.is('rootElement')

  const placement = useMemo(() => {
    if (!placed || isVersion) return
    // or see what parents are applicable
    var childContext: CK.ModelElement
    for (var node = context; node; node = node.parent as CK.ModelElement) {
      if (editor.model.schema.checkChild(node, placed.name)) {
        const placement =
          // try to place after previous context
          (childContext && refs.placements.find((p) => p.anchor?.context == childContext)) ||
          // try to place at the end of the context
          refs.placements.find((p) => p.parent?.context == node && (p.type == 'end' || p.type == 'inside'))
        if (placement) return placement
      }
      childContext = node
    }
  }, [placed])
  if (!placed) return

  return (
    <Box
      background={'#fff'}
      p={1}
      pr='3px'
      css={`
        margin-top: -40px;
        background: #fff;
        position: absolute;
        align-items: center;
        display: inline-flex;
        border: 1px solid #94949d;
        border-radius: 30px;
        height: 32px;
        white-space: nowrap;
      `}
      onMouseMoveCapture={(e) => {
        onPlacementHighlight(findEqualPlacement(placement, refs.placements))
      }}
      onMouseLeave={(e) => {
        onPlacementHighlight(null)
      }}
    >
      <Button
        variant='minimal'
        size='sm'
        h={6}
        w={6}
        minW={6}
        onClick={() => {
          onPlacementStart(null)
        }}
      >
        <Icon boxSize='icon-md'>
          <path d={mdiClose} />
        </Icon>
      </Button>
      <Text ml={1} fontSize='13px'>
        {!isDragging ? 'Place' : 'Positioning'}
        <Text as='span' fontWeight={'bold'} mr={1} ml={2}>
          <Icon boxSize='icon-md' mr='2px'>
            <path d={getContextIcon(placed)} />
          </Icon>
          {Style.Context.getContextLabel(placed)}
        </Text>{' '}
        {!isDragging ? 'on canvas' : placed.name == 'section' ? 'on top level' : null}
      </Text>
      {!isDragging && placement && (
        <Button
          variant='primary'
          size='sm'
          h={6}
          px={2}
          ml={1}
          autoFocus={true}
          fontWeight='normal'
          fontSize='11px'
          onClick={() => onPlacement(placement)}
        >
          {placement.parent.context == context ? 'Append inside ' : 'Insert after '}
          <Icon boxSize='icon-md' ml={1} mr='2px'>
            <path
              d={getContextIcon(
                (placement.parent.context == context ? context : placement.anchor?.context) as CK.ModelElement
              )}
            />
          </Icon>
          <strong style={{ fontSize: '13px' }}>
            {Style.Context.getContextLabel(placement.parent.context == context ? context : placement.anchor?.context)}
          </strong>
        </Button>
      )}
    </Box>
  )
}
