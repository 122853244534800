import { css } from '@emotion/react'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { Style } from '@sitecore-feaas/sdk'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { Measurement } from '../../../utils/dom.js'
import { Placement, Point } from '../../../utils/placement.js'
import { Rect } from '../../../utils/rect.js'
import { Grid } from './ChromeGrid.js'
export * from '../../../utils/placement.js'
export type { Placement }

// height of button is 24px
// clickable area is increased by 6px
// no focus ring, instead it has increased background size
export const ImplicitBorders = css`
  height: 24px;
  min-width: 24px;
  border-radius: 4px;
  position: relative;

  &:not(.primary) {
    color: var(--chakra-colors-blackAlpha-600);
    &:not([disabled]) {
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: #000;
      }
      &[data-active],
      &:active {
        color: var(--chakra-colors-primary-600);
        background-color: var(--chakra-colors-primary-100);
      }
    }
  }
  &:before {
    border: 4px solid transparent;
    transition: 0.2s border-color;
    border-radius: inherit;
    position: absolute;
    content: '';
    left: -4px;
    right: -4px;
    top: -4px;
    bottom: -4px;
  }
`
export type EditorChromeElement =
  | 'variable'
  | 'range'
  | 'block'
  | 'placed'
  | 'outline'
  | 'section'
  | 'context'
  | 'highlight'
  | 'place'
  | 'placement'

export type onEditorCommand = (name: string, ...values: any[]) => void
export type onEditorHighlight = (model: CK.ModelElement) => void

export type EditorChromeInputs = {
  root: HTMLElement
  focusable: HTMLElement
  selectionIndex: number
  placed: CK.ModelElement
  highlight: CK.ModelElement
} & CK.Editor['current']

export type EditorChromeProps = {
  editor: CK.Editor
  onHighlight: onEditorHighlight
  onCommand: onEditorCommand
  popover: string
  placement: Placement
  grids: Grid[]
  customStyles: Style.Rule[]
  onPlacementStart: (element: CK.ModelElement) => void
  onPlacementOver: (placement: Placement) => void
  onPlacementHighlight: (placement: Placement) => void
  onPlacement: (placement: Placement) => void
  onPopoverOpen: (name: string) => void
  onPopoverClose: (name?: string) => void
  onPopoverRegister: (name: string, callback: any) => void
  children?: any
} & EditorChromeInputs

export interface ChromeRefs {
  inputs: EditorChromeInputs
  memoized: EditorChromeInputs
  placements: Placement[]
  matchedPlacements: Placement[]
  pointer: Point
  scroll: Point
  measurements: Inputs<Measurement>
  clonables: Measurement[]
  variables: Measurement[]
  spacers: Measurement[]
  embeds: Measurement[]
}

export type Inputs<T> = {
  root: T
  highlight: T
  selection: T
  focusable: T
  context: T
  section: T
  block: T
  placed: T
}

export type ChromeOrchestratorProps = {
  refs: ChromeRefs
  props: EditorChromeProps
  conditions: Record<EditorChromeElement, boolean>
  context: EditorContext
  positions: Inputs<Rect>
  measurements: Inputs<Measurement>
  isVisible: boolean
}
