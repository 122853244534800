import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useToast,
  Icon
} from '@chakra-ui/react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { Style, Version as VersionType, VersionModel } from '@sitecore-feaas/sdk'
import { useContext, useMemo } from 'react'
import { VersionContext } from '../../../contexts/VersionContext.js'
import { useData, useLibrary } from '../../../hooks/useData.js'
import VersionBreakpointBadge from './VersionBreakpointBadge.js'

const VersionBreakpointsMenu = () => {
  const [{ breakpoint, snapshotStatus, version }, setContext] = useContext(VersionContext)
  const rules = useLibrary('stylesheets.first.rules')
  const allBreakpoints = Style.Set.filterByType(rules, 'breakpoint')
  const hasSelectedAll = !version.breakpointIds || version.breakpointIds.length === allBreakpoints.length
  const hasSelectedSome = version.breakpointIds?.length && version.breakpointIds?.length < allBreakpoints.length
  const hasSelectedNone = version.breakpointIds?.length === 0
  const stylesBreakpointIds = useMemo<VersionType['breakpointIds']>(
    () => allBreakpoints.map((b) => b.details.id),
    [allBreakpoints]
  )
  const toast = useToast()
  return (
    <Popover
      trigger={snapshotStatus ? null : 'click'}
      isLazy={true}
      onClose={() => {
        if (hasSelectedNone) {
          version.setBreakpointIds(null)
          requestAnimationFrame(() => {
            toast({
              isClosable: true,
              duration: 4000,
              status: 'warning',
              title: 'You selected no breakpoints',
              description: 'All sizes have been allowed'
            })
          })
        }
      }}
    >
      {({ isOpen }) => {
        return (
          <>
            <PopoverTrigger>
              <IconButton
                isDisabled={!!snapshotStatus}
                aria-label='Configure breakpoints'
                size='xs'
                icon={
                  isOpen ? (
                    <Icon boxSize='icon-lg'>
                      <path d={mdiChevronUp} />
                    </Icon>
                  ) : (
                    <Icon boxSize='icon-lg'>
                      <path d={mdiChevronDown} />
                    </Icon>
                  )
                }
                style={{ cursor: 'pointer' }}
              />
            </PopoverTrigger>
            <PopoverContent zIndex={20}>
              <Box p={2}>
                <CheckboxGroup
                  value={version.breakpointIds ? version.breakpointIds : stylesBreakpointIds}
                  onChange={(breakpoints: VersionType['breakpointIds']) => {
                    version.setBreakpointIds(breakpoints)
                    if (!breakpoints.includes(breakpoint?.details?.id)) {
                      const selectedBreakpoints = allBreakpoints.filter((breakpoint) =>
                        breakpoints.includes(breakpoint.details.id)
                      )
                      setContext({
                        breakpoint: selectedBreakpoints.length ? selectedBreakpoints[0] : allBreakpoints[0]
                      })
                    }
                  }}
                >
                  <Flex justify={'space-between'} p={1.5}>
                    <Checkbox
                      {...{ colorScheme: 'primary' }}
                      isIndeterminate={hasSelectedSome}
                      isChecked={hasSelectedAll}
                      onChange={() => {
                        version.setBreakpointIds(hasSelectedAll ? [] : null)
                      }}
                    >
                      <VersionBreakpointBadge breakpoint={null} />
                    </Checkbox>
                  </Flex>
                  {allBreakpoints.map((breakpoint: Style.Rule<'breakpoint'>) => (
                    <Flex justify={'space-between'} p={1.5} pl={6} key={breakpoint.details.id}>
                      <Checkbox
                        {...{ colorScheme: 'primary' }}
                        value={breakpoint.details.id}
                        isChecked={!version.breakpointIds || version.breakpointIds.includes(breakpoint.details.id)}
                      >
                        <VersionBreakpointBadge breakpoint={breakpoint} />{' '}
                      </Checkbox>
                      <Box fontWeight={700} fontSize={'12px'}>
                        {breakpoint.details.description}
                      </Box>
                    </Flex>
                  ))}
                </CheckboxGroup>
              </Box>
              {hasSelectedNone ? (
                <Alert status='warning' borderRadius={0}>
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Please select some breakpoints</AlertTitle>
                    <AlertDescription>Otherwise all breakpoints will be allowed</AlertDescription>
                  </Box>
                </Alert>
              ) : (
                <Alert borderRadius={0}>
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Assign breakpoint size to use them within responsive bundles</AlertTitle>
                    <AlertDescription>
                      Breakpoints are for preview only. To create a layout specific to a breakpoint, add a version.
                    </AlertDescription>
                  </Box>
                </Alert>
              )}
            </PopoverContent>
          </>
        )
      }}
    </Popover>
  )
}

export default VersionBreakpointsMenu
