import {
  Button,
  Divider,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip
} from '@chakra-ui/react'
import { mdiArrangeBringForward, mdiArrangeSendBackward, mdiContentCopy, mdiTrashCanOutline } from '@mdi/js'
import * as CK from '@sitecore-feaas/ckeditor5'
import { EditorChromeProps, ImplicitBorders } from './ChromeContext.js'

export default function ChromeContextActions({
  editor,
  context,
  grids,
  onCommand,
  onPopoverClose,
  onPopoverOpen
}: EditorChromeProps) {
  const grid = grids.find((g) => g.context == context?.parent)
  const gridItem = grid?.props.items[context?.index]
  const overlaps = (gridItem && grid?.getOverlappingItems(gridItem)) || []
  const previousOverlap = grid?.props.items.findLastIndex((item, i) => overlaps.includes(item) && i < context.index)
  const nextOverlap = grid?.props.items.findIndex((item, i) => overlaps.includes(item) && i > context.index)
  const hasOverlaps = nextOverlap > -1 || previousOverlap > -1
  return (
    <>
      {hasOverlaps && (
        <Tooltip label={'Bring forward'} placement='top' isDisabled={nextOverlap == -1}>
          <IconButton
            isDisabled={nextOverlap == -1}
            css={ImplicitBorders}
            onClick={() => {
              editor.model.change((writer) => {
                const index = context.index
                const items = grid.props.items.slice()
                const target = context.parent.getChild(nextOverlap)
                items.splice(nextOverlap + 1, 0, gridItem)
                items.splice(index, 1)
                editor.styles.setContextStyle(context.parent as CK.ModelElement, {
                  details: grid.details,
                  type: grid.type,
                  props: {
                    ...grid.props,
                    items: items
                  }
                })
                writer.move(writer.createRangeOn(context), writer.createPositionAfter(target))
                editor.execute('setContext', context)
              })
            }}
            icon={
              <Icon boxSize='icon-lg'>
                <path d={mdiArrangeBringForward} />
              </Icon>
            }
            aria-label='Bring forward'
          />
        </Tooltip>
      )}
      {hasOverlaps && (
        <Tooltip label={'Bring backward'} placement='top' isDisabled={previousOverlap == -1}>
          <IconButton
            isDisabled={previousOverlap == -1}
            css={ImplicitBorders}
            onClick={() => {
              editor.model.change((writer) => {
                const index = context.index
                const items = grid.props.items.slice()
                const target = context.parent.getChild(previousOverlap)
                items.splice(index, 1)
                items.splice(previousOverlap, 0, gridItem)
                editor.styles.setContextStyle(context.parent as CK.ModelElement, {
                  details: grid.details,
                  type: grid.type,
                  props: {
                    ...grid.props,
                    items: items
                  }
                })
                writer.move(writer.createRangeOn(context), writer.createPositionBefore(target))
                editor.execute('setContext', context)
              })
            }}
            icon={
              <Icon boxSize='icon-lg'>
                <path d={mdiArrangeSendBackward} />
              </Icon>
            }
            aria-label='Bring backward'
          />
        </Tooltip>
      )}
      {(previousOverlap > -1 || nextOverlap > -1) && <Divider orientation='vertical' />}
      {context?.getAttribute('data-symbol-ref') == null && (
        <Menu
          flip={false}
          autoSelect={true}
          isLazy={true}
          onOpen={() => onPopoverOpen('duplicate')}
          onClose={() => onPopoverClose('duplicate')}
        >
          <Tooltip label='Duplicate element' placement='top'>
            <MenuButton
              px={'3px'}
              as={Button}
              aria-label='Actions menu'
              isDisabled={context?.name == '$root'}
              variant='minimal'
              css={ImplicitBorders}
            >
              <Icon boxSize='icon-lg'>
                <path d={mdiContentCopy} />
              </Icon>
            </MenuButton>
          </Tooltip>
          <MenuList>
            <MenuGroup>
              <MenuItem
                icon={
                  <Icon>
                    <path d={mdiContentCopy} />
                  </Icon>
                }
                onClick={() => onCommand('duplicate')}
              >
                Duplicate
              </MenuItem>
              {context && context.name != 'section' && context.parent?.name != 'section' && (
                <MenuItem
                  icon={
                    <Icon>
                      <path d={mdiContentCopy} />
                    </Icon>
                  }
                  onClick={() => onCommand('symbol')}
                >
                  Duplicate linked
                </MenuItem>
              )}
            </MenuGroup>
          </MenuList>
        </Menu>
      )}
      <Tooltip label={<div style={{ textAlign: 'center' }}>Delete element</div>} placement='top'>
        <IconButton
          css={ImplicitBorders}
          isDisabled={!editor.commands.get('deleteElement').isEnabled}
          onClick={() => onCommand('deleteElement')}
          icon={
            <Icon boxSize='icon-lg'>
              <path d={mdiTrashCanOutline} />
            </Icon>
          }
          aria-label='Delete element'
        />
      </Tooltip>
    </>
  )
}
