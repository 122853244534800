import { Box, Flex, VStack } from '@chakra-ui/react'
import { EditorWatchdog } from '@ckeditor/ckeditor5-watchdog'
import type * as CK from '@sitecore-feaas/ckeditor5'
import * as clientside from '@sitecore-feaas/clientside/headless'
import { ComponentModel, DatasourceModel, LibraryModel, SDK, VersionModel } from '@sitecore-feaas/sdk'
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo } from 'react'
import { EditorContext, EditorContextSetter, useEditorContextProvider } from '../../contexts/EditorContext.js'
import { SlotedChildren, useSlots } from '../../hooks/useSlots.js'
import { getActiveElement } from '../../utils/dom.js'
import { QueryStringContext } from '../providers/QueryStringProvider.js'

interface EditorCanvasProps {
  sdk: SDK
  library: LibraryModel
  datasources: DatasourceModel[]
  versions?: VersionModel[]
  component: ComponentModel
  context?: Partial<EditorContext>
  children?: SlotedChildren<
    [context: EditorContext, setter: EditorContextSetter],
    'before' | 'children' | 'extras' | 'after' | 'chrome' | 'picker' | 'exports'
  >
}

export default function Editor({
  sdk,
  library,
  datasources,
  component,
  children,
  versions = component.versions,
  context
}: EditorCanvasProps) {
  const editorContextWithSetter = useEditorContextProvider(context)
  const [editorContext, setEditorContext] = editorContextWithSetter
  const {
    editorClass,
    node,
    editor,
    status,
    isDataDisplayed,
    isHiddenDisplayed,
    isFocused,
    activeVersionId,
    onPlacementStart
  } = editorContext
  const { onSave, setActiveVersionId } = EditorData({
    editor,
    editorContext,
    setEditorContext,
    library,
    datasources,
    component,
    versions
  })
  EditorInitialize({ sdk, library, node, onSave, datasources, editor, editorClass, setEditorContext })
  const { blur, focus } = EditorFocus({ editor, setEditorContext, isFocused, activeVersionId })
  EditorDOM({ editor, node, blur })

  const onVersionMove: EditorContext['onVersionMove'] = (id, beforeId) => {
    component.findVersion(id).move(beforeId, 'after')
  }

  Object.assign(editorContext, { editor, blur, focus, onVersionMove, setActiveVersionId, ...context })
  const [query] = useContext(QueryStringContext)

  useEffect(() => {
    if (editorContext.status !== 'ready' || !query.datapicker) return
    setEditorContext({ sidebarMode: 'datasources', sidebarDialog: null })
  }, [editorContext.status, query])

  useEffect(() => {
    if (!editor || status == 'ready') return

    if (component && versions.length != 0) {
      editor.execute('setContext', editor.model.document.getRoot(editor.model.document.getRootNames()[0]))
      setEditorContext({
        status: 'ready'
      })
      ;(document.querySelector('feaas-context')?.shadowRoot || document)
        ?.querySelector('feaas-loader-app, feaas-loader')
        ?.setAttribute('hidden', 'hidden')
      editor.fire('ready')
    } else {
      setEditorContext({
        status: 'initialized'
      })
    }
  }, [editor, component, versions, versions.length])
  return (
    <EditorContext.Provider value={editorContextWithSetter}>
      {useSlots(
        children,
        ({ children, before, after, chrome, picker, extras }) => (
          <>
            <Box
              className='editor-content'
              maxHeight='100%'
              minHeight='100%'
              flexGrow={1}
              position='relative'
              overflowY={'scroll'}
              overflowX='hidden'
            >
              <Flex
                minHeight='100%'
                display='flex'
                alignItems={'center'}
                direction='column'
                flexGrow={1}
                px={'24px'}
                sx={{
                  '@media screen and (min-width: 1400px)': {
                    px: '48px'
                  }
                }}
                ref={(node: HTMLDivElement) =>
                  !editorContext.node &&
                  node &&
                  setEditorContext({ node, root: ((node.getRootNode() as ShadowRoot).host as HTMLElement) || node })
                }
              >
                {chrome}
                <style>{[library.stylesheet.css].join('\n')}</style>
                {before}
                <VStack
                  spacing={'84px'}
                  aria-label={status == 'ready' ? 'Content Editor' : 'Loading Editor'}
                  opacity={status == 'ready' ? 1 : 0}
                  transition='opacity 0.3s'
                  pointerEvents={status == 'ready' ? 'all' : 'none'}
                  position={status == 'ready' ? 'static' : 'absolute'}
                  onContextMenu={(e) => e.preventDefault()}
                  alignSelf='stretch'
                >
                  {(children || []).concat(extras || [])}
                </VStack>
                {after}
              </Flex>
            </Box>

            {picker}
          </>
        ),
        editorContext,
        setEditorContext
      )}
    </EditorContext.Provider>
  )
}

// Lazily load & initialize CKEDitor
function EditorInitialize({
  sdk,
  library,
  datasources,
  editor,
  node,
  onSave,
  editorClass,
  setEditorContext
}: {
  sdk: SDK
  library: LibraryModel
  datasources: DatasourceModel[]
  editor: CK.Editor
  node: HTMLDivElement
  onSave: (editor: CK.Editor) => Promise<any>
  editorClass: typeof CK.Editor
  setEditorContext: EditorContextSetter
}) {
  // Prepare config for CKEditor5
  const config = useMemo<CK.EditorConfig>(() => {
    return {
      data: {
        datasources,
        stylesheet: library.stylesheet
      },
      modules: {
        getSDK: () => sdk,
        clientside
      },
      autosave: {
        waitingTime: 5000,
        save: onSave
      }
    }
  }, [])

  // Load CKEditor5 ondemand
  useEffect(() => {
    if (!editorClass) {
      import('@sitecore-feaas/ckeditor5').then(({ Editor }) => {
        window.CKEDITOR_VERSION = null
        setEditorContext({ editorClass: Editor })
      })
    }
  }, [])

  // initialize editor watchdog that captures crashes
  useEffect(() => {
    if (editorClass == null) return
    const watchdog = new EditorWatchdog(null)

    // @ts-ignore
    watchdog.setCreator(async (elementOrData) => {
      const editor = new editorClass(config)
      await editor.initPlugins()
      setTimeout(() => {
        if (window.location.search.includes('inspect')) {
          // @ts-ignore
          import('@ckeditor/ckeditor5-inspector').then(({ default: CKEditorInspector }) => {
            CKEditorInspector.attach(editor)
          })
        }
      }, 500)
      setEditorContext({ editor })
      return editor
    })

    sdk.log('Editor is initializing', node)
    if (node) {
      watchdog.create({}, {
        container: node,
        ...config
      } as any)
    }
  }, [node, editorClass])

  // destroy editor when component is unloaded
  useEffect(() => {
    if (!editor) return
    sdk.log('Editor initialized')
    return () => {
      sdk.log('Editor is destroyed', editor)
      editor.destroy()
    }
  }, [editor])

  return editor
}

// All the concerns of loading data in and out of editor
function EditorData({
  editor,
  editorContext,
  setEditorContext,
  library,
  datasources,
  component,
  versions
}: {
  editor: CK.Editor
  editorContext: EditorContext
  setEditorContext: EditorContextSetter
  library: LibraryModel
  datasources: DatasourceModel[]
  component: ComponentModel
  versions: VersionModel[]
}) {
  // Commit current data to versions and create "saved" versions
  const saveAllVersions = (editor: CK.Editor) => {
    editor.model.document.getRootNames().forEach((rootName) => {
      if (editor.data.get({ rootName }) != null)
        component.findVersion(rootName)?.saveData({
          view: editor.getData({ rootName }),
          model: ''
        })
    })
  }

  // Commit changes from CKEditor to the backend
  const onSave = useCallback(
    async (editor: CK.Editor) => {
      saveAllVersions(editor)
      component.aggregateVersionData()
      return component.saveVersions(editorContext.isAutosaveEnabled)
    },
    [editor]
  )

  useEffect(() => {
    editor?.on('change:current', (evt, prop, current) => {
      setEditorContext((old) => ({
        context: current.context,
        current: current,
        sidebarMode: current.context == old.context ? old.sidebarMode : null,
        sidebarDialog: current.context == old.context ? old.sidebarDialog : null
      }))
    })
  }, [editor])

  useEffect(() => {
    if (!editorContext.isFocused) {
      setEditorContext({
        sidebarMode: null,
        sidebarDialog: null
      })
    }
  }, [editorContext.isFocused])

  // Sync model saving status with CKEditor's own autosave
  useEffect(() => {
    editor?.autosavePlugin.on('change:state', (evt, prop, value) => {
      editor.log('Autosave state:', value)
      setEditorContext({
        savingStatus: value
      })
    })
  }, [editor])

  // Create drafts of versions that have been changed
  useEffect(() => {
    editor?.model.document.on('change:data', (evt, batch) => {
      editor.log('Data change', batch)
      if (editorContext.activeVersionId && !editor['suppressedChanges']) {
        editorContext?.onContentChange?.()
        component.findVersion(editorContext.activeVersionId)?.getDraft()
      }
    })
  }, [editor])

  // schedule auto save on version change
  useEffect(() => {
    if (component && versions.length > 0 && editorContext.isAutosaveEnabled) {
      editor?.scheduleSave()
    }
  }, [editor, versions])

  // save on status change
  useEffect(() => {
    if (component && versions.length > 0) {
      editor?.save(true)
    }
  }, [editor, versions.filter((r) => r.status != 'draft').length, versions.filter((r) => r.deletedAt != null).length])

  // save on leaving editor
  useLayoutEffect(() => {
    return () => {
      if (editor) {
        onSave(editor)
        component.aggregateVersionData()
        library.components.replaceItem(component)
      }
    }
  }, [editor])

  // pass options from UI to editor
  useEffect(() => {
    // @ts-ignore FIXME: Currently PluginInterface doesnt have .set
    editor?.plugins.get('Preview').set('isDataRepeated', editorContext.isDataRepeated)
    // @ts-ignore FIXME: Currently PluginInterface doesnt have .set
    editor?.plugins.get('Preview').set('isDataDisplayed', editorContext.isDataDisplayed)
  }, [editor, editorContext.isDataDisplayed, editorContext.isDataRepeated])

  // pass datasource updates to editor
  useEffect(() => {
    editor?.set('datasources', datasources)
  }, [editor, datasources])

  // pass stylesheet updates to editor
  useEffect(() => {
    editor?.set('stylesheet', library.stylesheet)
  }, [editor, library.stylesheet])

  const setActiveVersionId = useCallback(
    (versionId: string) => {
      setEditorContext({
        isFocused: true,
        activeVersionId: versionId
      })
    },
    [editor, versions]
  )

  return { onSave, setActiveVersionId }
}

// Deal with focus of versions and UI
function EditorFocus({
  editor,
  setEditorContext,
  isFocused,
  activeVersionId
}: {
  editor: CK.Editor
  isFocused: boolean
  activeVersionId: string

  setEditorContext: (props: Partial<EditorContext>) => any
}) {
  // Set focus to specific document rootName
  const focus = useCallback(
    (rootName: string) => {
      return setTimeout(() => {
        if (!editor) return
        const rootElement = editor.editing.view.getDomRoot(rootName) as any as HTMLElement
        const root = editor.model.document.getRoot(rootName)
        editor.model.enqueueChange({ isUndoable: false }, () => {
          editor.execute('setContext', root)
        })
        rootElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        rootElement?.focus()
      }, 50)
    },
    [editor]
  )
  // Blur editor focus
  const blur = useCallback(() => {
    if (!editor.model.document.selection.isCollapsed)
      editor.model.enqueueChange({ isUndoable: false }, (writer) => {
        writer.setSelection(editor.model.document.selection.focus)
      })
    // @ts-ignore active elements have blur
    getActiveElement().blur()
    setEditorContext({
      isFocused: false
    })
    editor.set('isFocused', false)
  }, [editor])

  useEffect(() => {
    editor?.set({
      isFocused,
      activeRootName: activeVersionId
    })
  }, [editor, isFocused, activeVersionId])

  return { focus, blur }
}

// Raw DOM bindings of UI
function EditorDOM({ editor, node, blur }: { editor: CK.Editor; node: HTMLDivElement; blur: () => void }) {
  // Stop link/button clicks within contenteditable unless modifier keys are held
  useEffect(() => {
    if (node && editor) {
      const onMouseDown = (e: MouseEvent) => {
        const target = e.composedPath()[0]
        if (!(target instanceof HTMLElement)) return
        if (!target.closest('[contenteditable], .ui, .chakra-select')) {
          if (editor && document.activeElement instanceof HTMLElement) {
            blur()
          }
        }
      }
      const onClick = (e: MouseEvent) => {
        if (!(e.target instanceof HTMLElement)) return
        if (e.target.closest('[contenteditable], .preview')) {
          if (
            (e.target.closest('a, button') || (e.composedPath()[0] as HTMLElement).closest('a, button')) &&
            !e.metaKey &&
            !e.ctrlKey
          ) {
            e.preventDefault()
            e.stopPropagation()
          }
        }
      }
      const onDragStart = (e: MouseEvent) => {
        if (!(e.target instanceof HTMLElement)) {
          if (editor.current.context?.name == 'section') e.preventDefault()
        } else if (!e.target.closest('[draggable]')) {
          e.preventDefault()
        }
      }
      if (node.ownerDocument != document) node.addEventListener('click', onClick)
      document.addEventListener('click', onClick)
      document.addEventListener('mousedown', onMouseDown, false)
      document.addEventListener('dragstart', onDragStart, false)
      return () => {
        if (node.ownerDocument != document) node.removeEventListener('click', onClick)
        document.removeEventListener('click', onClick)
        document.removeEventListener('mousedown', onMouseDown, false)
        document.removeEventListener('dragstart', onDragStart, false)
      }
    }
  }, [node, editor])

  // Handle Undo/redo hotkeys
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey) {
        node.classList.add('allow-interaction')
      }
      if (!event.ctrlKey && !event.metaKey && !event.altKey) return
      if ((event.target as HTMLElement).closest('[contenteditable], input, textarea')) return

      if (!event.shiftKey && event.key.toLowerCase() === 'z') {
        editor.execute('undo')
      }
      if (event.key === 'y' || (event.shiftKey && event.key.toLowerCase() == 'z')) {
        editor.execute('redo')
      }
    }

    const onKeyUp = (event: KeyboardEvent) => {
      if (!event.metaKey) {
        node.classList.remove('allow-interaction')
      }
    }

    document.addEventListener('keydown', onKeyDown, true)
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keydown', onKeyDown, true)
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [node, editor])

  // Automatically load all scripts for embedded components within CKEditor5 instance
  useEffect(() => {
    if (!editor) return
    const observer = clientside.autoloadScripts(node)
    return () => {
      observer.disconnect()
    }
  }, [editor])
}
