import { Box, Button, Flex, Icon, StackDivider, Text, Tooltip, VStack } from '@chakra-ui/react'
import { Style, StylesheetModel } from '@sitecore-feaas/sdk'
import { getIconPathByElementName } from '../../../utils/element.js'
import { useSDK } from '../../../hooks/useData.js'

const titles = {
  block: 'Block elements',
  text: 'Text elements',
  inline: 'Inline elements',
  external: 'External elements'
}

export type ElementChoices = Record<keyof typeof titles, Style.ElementDefinition[]>

export default function ChromeElementTypes({
  rules: rules,
  category,
  value,
  onChange
}: {
  rules: Style.Rule[]
  category?: string
  value?: string
  onChange: (definition: Style.ElementDefinition) => void
}) {
  const expandedComponents = useSDK('renderingHost.expandedComponents')
  const definitions = Style.Set.getElementDefinitions(rules)
  const types: ElementChoices = Object.keys(titles).reduce((types, key) => {
    return Object.assign(types, {
      [key]: definitions.filter((def) => (def.group || def.type) == key)
    })
  }, {} as ElementChoices)

  return (
    <VStack
      boxShadow='md'
      p={4}
      py={5}
      alignItems='stretch'
      spacing={4}
      divider={<StackDivider />}
      aria-label='Element menu items'
    >
      {Object.keys(titles).map((key) => {
        if (
          category != null &&
          key != category &&
          !(category == 'block' && key == 'external') &&
          !(category == 'text' && key == 'inline') &&
          !(category == 'inline' && key == 'text')
        )
          return
        return (
          <Box key={key}>
            <Text color='gray.400' fontWeight={'bold'} fontSize='sm' mt={-1}>
              {titles[key as keyof typeof titles]}
            </Text>
            <Flex flexWrap={'wrap'}>
              {types[key as keyof typeof types]
                .sort((a, b) => {
                  return (a.priority ?? 0) - (b.priority ?? 0)
                })
                .map((definition) => {
                  const { type, label, name, className, description } = definition
                  if (value != null && (name == 'section' || name == 'var' || name == 'link' || name == 'spacer'))
                    return
                  if (name == 'form' && !expandedComponents.find((e) => e.name == 'SitecoreForm')) return
                  if (definition.type == definition.name) return
                  if (definition.name == 'header4' || definition.name == 'header5')
                    if (Style.Set.getDefinitionElementChoices(rules, definition)?.length == 0) return
                  const isActive = value != null && value == className
                  return (
                    <Tooltip label={description} placement='top-start' key={name}>
                      <Button
                        onClick={() => onChange(definition)}
                        isActive={isActive}
                        background={isActive ? 'primary.100 !important' : null}
                        color={isActive ? 'primary.900' : null}
                        borderRadius={'4px'}
                        mr={'3'}
                        mt={'3'}
                        variant='solid'
                        size='xs'
                        aria-label={`Add ${label}`}
                        leftIcon={
                          <Icon boxSize={'icon-md'}>
                            <path d={getIconPathByElementName(name) || getIconPathByElementName(type)} />
                          </Icon>
                        }
                      >
                        {label}
                      </Button>
                    </Tooltip>
                  )
                })}
            </Flex>
          </Box>
        )
      })}
    </VStack>
  )
}
