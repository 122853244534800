import {
  Button,
  ButtonProps,
  Icon,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { mdiPlus } from '@mdi/js'
import { useLibrary } from '../../../hooks/useData.js'
import { useSlots } from '../../../hooks/useSlots.js'
import { getActiveElement } from '../../../utils/dom.js'
import { EditorChromeProps, ImplicitBorders } from './ChromeContext.js'
import ChromeElementTypes from './ChromeElementTypes.js'

export default function ChromeContextAdd({
  context,
  root,
  editor,
  onPlacementStart: onPlace,
  onCommand,
  onPopoverClose,
  onPopoverOpen,
  children,
  allowInline,
  ...props
}: ButtonProps &
  EditorChromeProps & {
    allowInline?: boolean
  }) {
  const stylesheet = useLibrary('stylesheets.first')

  return useSlots(children, ({ button }) => (
    <Popover
      placement='bottom-end'
      flip={false}
      onOpen={() => {
        onPopoverOpen('add')
      }}
      isLazy={true}
      onClose={() => {
        onPopoverClose('add')
      }}
    >
      <PopoverTrigger>
        {button || (
          <Button
            aria-label='Element menu'
            variant='primary'
            className='primary'
            css={css`
              ${ImplicitBorders}
              padding: 4px 8px;
              margin-left: 3px;
              &[class][class][class] {
                :not(.chakra-button__icon) {
                  font-size: 11px;
                }
                border-radius: 32px;
              }
            `}
            leftIcon={
              <Icon boxSize='icon-sm'>
                <path d={mdiPlus} />
              </Icon>
            }
          >
            {props.size == 'sm' ? '' : 'Add element'}
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent width={'375px'}>
        <PopoverCloseButton m={2} />
        <ChromeElementTypes
          value={null}
          rules={stylesheet.rules}
          onChange={(definition) => {
            getActiveElement().blur()
            editor.model.change((writer) => {
              onPlace(
                editor.styles.setContextName(
                  editor.styles.createContext(definition, writer),
                  definition.type,
                  definition.name
                )
              )
            })
          }}
        />
      </PopoverContent>
    </Popover>
  ))
}
