import {
  Box,
  Button,
  Icon,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { mdiChevronDown } from '@mdi/js'
import { Style } from '@sitecore-feaas/sdk'
import { getContextIcon, getIconPathByElementName } from '../../../utils/element.js'
import { EditorChromeProps, ImplicitBorders } from './ChromeContext.js'
import { ContextName } from './ChromeContextParent.js'
import ChromeElementTypes from './ChromeElementTypes.js'

export default function ChromeContextType(props: EditorChromeProps) {
  const { onPopoverOpen, onPopoverClose, onCommand, context, editor, onHighlight } = props
  const classList = Style.Context.getClassList(context)
  const genericClassName = Style.ClassList.getElementGenericClassName(classList)
  const category = Style.Conventions.getElementType(genericClassName)
  const isDisabled = genericClassName == '-section'
  return (
    <>
      <Popover
        placement='bottom-start'
        onOpen={() => {
          onPopoverOpen('add')
        }}
        isLazy={true}
        onClose={() => {
          onPopoverClose('add')
        }}
      >
        <PopoverTrigger>
          <Box>
            <Tooltip label='Change element type' placement='top' isDisabled={isDisabled}>
              <Button
                isDisabled={isDisabled}
                size='sm'
                css={ImplicitBorders}
                aria-label='Selected element'
                display='flex'
                rightIcon={
                  !isDisabled && (
                    <Icon boxSize='icon-lg' mr={'-8px'} ml={'-4px'}>
                      <path d={mdiChevronDown} />
                    </Icon>
                  )
                }
                leftIcon={
                  <Icon boxSize='icon-lg' ml={'-8px'}>
                    <path d={getContextIcon(context)} />
                  </Icon>
                }
              >
                <ContextName context={context} />
              </Button>
            </Tooltip>
          </Box>
        </PopoverTrigger>
        <PopoverContent width='365px'>
          <PopoverCloseButton m={2}></PopoverCloseButton>
          <ChromeElementTypes
            value={genericClassName}
            category={category}
            rules={editor.stylesheet.rules}
            onChange={(definition) => {
              editor.styles.setContextName(context, definition.type, definition.name)
            }}
          />
        </PopoverContent>
      </Popover>
    </>
  )
}
