import { HStack, Icon, Link, Skeleton, Text, Tooltip } from '@chakra-ui/react'
import { mdiInformation, mdiInformationOutline } from '@mdi/js'
import { ComponentModel, LibraryModel } from '@sitecore-feaas/sdk'

export function EditorUIBreadcrumbs({ component, library }: { component: ComponentModel; library: LibraryModel }) {
  return (
    <HStack
      whiteSpace='nowrap'
      minWidth='0'
      fontSize='14'
      spacing='3'
      mr='4'
      divider={
        <Text as='span' mx={4} color={'gray.500'} fontSize='15px'>
          /
        </Text>
      }
    >
      <Link href={`${library.getPath()}/collections`}>Library</Link>

      <Skeleton
        isLoaded={!!component}
        style={{
          height: '20px',
          flexShrink: 2,
          verticalAlign: 'baseline',
          minWidth: component.collection ? '0px' : '50px',
          display: 'inline-block'
        }}
      >
        <Link
          noOfLines={0}
          display='block'
          href={`${library.getPath()}/collections?collectionId=${component.collectionId}`}
        >
          Collection:&nbsp; {component.collection?.name}
        </Link>
      </Skeleton>

      <Skeleton
        isLoaded={!!component}
        style={{
          height: '20px',
          flexShrink: 1,
          verticalAlign: 'baseline',
          minWidth: component ? '0' : '50px',
          display: 'inline-block'
        }}
      >
        {(component && (
          <Text
            noOfLines={0}
            color='gray.800'
            style={{ height: '20px', verticalAlign: 'baseline', minWidth: '50px', display: 'block' }}
          >
            {component.name}
            {component.description && (
              <Tooltip label={component.description}>
                <Icon fontSize='icon-lg' ml={2} mt={'-2px'}>
                  <path d={mdiInformationOutline} />
                </Icon>
              </Tooltip>
            )}
          </Text>
        )) ||
          '&nbsp;'}
      </Skeleton>
    </HStack>
  )
}
