import { ButtonGroup, ButtonProps, Divider, Icon, IconButton, Tooltip } from '@chakra-ui/react'
import { mdiRedo, mdiUndo } from '@mdi/js'
import { useContext, useEffect, useState } from 'react'
import { EditorContext } from '../../../contexts/EditorContext.js'

export function EditorUIUndo(props: { size?: ButtonProps['size'] } & Pick<EditorContext, 'editor'>) {
  const [editorContext] = useContext(EditorContext)
  const { size, editor } = { ...editorContext, ...props }
  const [{ canUndo, canRedo }, setButtonState] = useState({
    canUndo: false,
    canRedo: false
  })

  useEffect(() => {
    if (editor) {
      const getButtonState = () => ({
        canUndo: editor?.commands.get('undo').isEnabled || false,
        canRedo: editor?.commands.get('redo').isEnabled || false
      })
      editor.commands.get('undo').on('change:isEnabled', () => {
        setButtonState(getButtonState)
      })
      editor.commands.get('redo').on('change:isEnabled', () => {
        setButtonState(getButtonState)
      })
      setButtonState(getButtonState)
    }
  }, [editor])

  return (
    <ButtonGroup isAttached={true} background={'#fff'} borderRadius='200px' size={size} className='ui'>
      <Tooltip label='Undo'>
        <IconButton
          isDisabled={!canUndo}
          onClick={() => editor.execute('undo')}
          icon={
            <Icon boxSize={size != 'sm' ? 'icon-xl' : 'icon-sm'}>
              <path d={mdiUndo} />
            </Icon>
          }
          aria-label='Undo'
          variant='primary'
        />
      </Tooltip>
      <Divider orientation='vertical' height='full' />
      <Tooltip label='Redo'>
        <IconButton
          isDisabled={!canRedo}
          onClick={() => editor.execute('redo')}
          icon={
            <Icon boxSize={size != 'sm' ? 'icon-xl' : 'icon-sm'}>
              <path d={mdiRedo} />
            </Icon>
          }
          aria-label='Redo'
          variant='primary'
        />
      </Tooltip>
    </ButtonGroup>
  )
}
