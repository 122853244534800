import { Alert, AlertDescription, AlertTitle, Box, Flex, HStack, Icon, Tooltip, VStack } from '@chakra-ui/react'
import { mdiCellphoneLink, mdiCheckCircleOutline } from '@mdi/js'
import { Style, VersionModel } from '@sitecore-feaas/sdk'
import { useContext, useEffect, useMemo, useRef } from 'react'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { VersionContext, VersionContextSetter, useVersionContextProvider } from '../../../contexts/VersionContext.js'
import { useLibrary } from '../../../hooks/useData.js'
import { SlotedChildren, useSlots } from '../../../hooks/useSlots.js'
import BarMenu from '../../BarMenu.js'
import { VersionBreakpointsList } from './VersionBreakpointList.js'
import VersionBreakpointsMenu from './VersionBreakpointsMenu.js'
import VersionGridButton from './VersionGridButton.js'
import VersionMenu from './VersionMenu.js'
import VersionName from './VersionName.js'
import VersionPreview from './VersionPreview.js'
import VersionPreviewAlert from './VersionPreviewAlert.js'
import VersionStatusButton from './VersionStatusButton.js'
import VersionStatusList from './VersionStatusList.js'
import VersionThemeButton from './VersionThemeButton.js'

export {
  VersionStatusList,
  VersionBreakpointsMenu,
  VersionMenu,
  VersionName,
  VersionPreview,
  VersionPreviewAlert,
  VersionStatusButton,
  Version
}

declare type Props = {
  version: VersionModel
  isHidden?: boolean
  node?: HTMLElement
  ignoreBreakpoints?: boolean
  children?: SlotedChildren<[context: VersionContext, setter: VersionContextSetter]>
  deps?: any[]
}

export default function Version({ node, ignoreBreakpoints, version, children, isHidden, deps = [] }: Props) {
  const { id, name, view, error, deletedAt } = version
  const refContent = useRef<HTMLDivElement>(null)

  const [editorContext, setEditorContext] = useContext(EditorContext)
  const {
    editor,
    isFocused,
    activeVersionId,
    isDataDisplayed,
    isDataRepeated,
    isHiddenDisplayed,
    sidebarMode,
    setActiveVersionId
  } = editorContext
  const versionContextWithSetter = useVersionContextProvider({ version })
  const [versionContext, setVersionContext] = versionContextWithSetter
  //
  Object.assign(versionContext, { version })
  const { breakpoint, needsWrite, snapshotStatus, openBar } = versionContext

  const isVersionActive = activeVersionId == version.id
  const isVersionFocused = isFocused && isVersionActive
  const isVersionDeleted = version.isDeleted()
  const staged = version.getCurrent('staged')
  const stylesheet = useLibrary('stylesheets.first')
  const themeSlug = version.getThemeSlug()
  const theme = useMemo(() => {
    return (
      Style.Set.findBySlug(stylesheet.rules, 'theme', version.getThemeSlug()) ||
      Style.Set.findBySlug(stylesheet.rules, 'theme', 'default')
    )
  }, [themeSlug])

  function getElement() {
    return node || refContent.current?.querySelector('.-feaas')
  }

  function setHTML() {}

  useEffect(() => {
    if (!editor) return
    var element = getElement()

    // create element if none given (when not using editor externally)
    if (!element) {
      element = document.createElement('div')
      element.classList.add('-feaas')
      refContent.current.insertBefore(element, refContent.current.firstElementChild)
    }

    // register node in the editor
    editor.suppressedChanges = true
    try {
      editor.model.enqueueChange({ isUndoable: false }, (writer) => {
        const root = editor.model.document.getRoot(id) || editor.addRoot(id, element)
        writer.setAttribute('class', version.classList.join(' '), root)
        editor.setRootContent(id, view)
      })
    } catch (e) {
      version.set({ error: e })
    }
    editor.suppressedChanges = false
    // listen to on focus event, but not Blur which is handled by outsideClick
    const onFocus = () => {
      setActiveVersionId(id)
    }
    element.addEventListener('focus', onFocus)
    return () => {
      editor.suppressedChanges = true
      if (editor.model.document.getRoot(id)) editor.removeRoot(id, element)
      editor.suppressedChanges = false

      element.removeEventListener('focus', onFocus)
    }
  }, [editor, node, id])

  useEffect(() => {
    var element = getElement()
    if (!editor || !element) return
    element.className = `-feaas ${version.classList.join(' ')} ${
      breakpoint ? `-emulate--${breakpoint.details.id} ` : ''
    }`
  }, [editor, version.classList, breakpoint, node])

  useEffect(() => {
    refContent.current?.ownerDocument.documentElement.classList.toggle('-editor-show-hidden', isHiddenDisplayed)
    refContent.current?.ownerDocument.documentElement.classList.toggle(
      '-editor-grid-mode',
      editor?.current?.context?.name == 'section'
    )
  }, [editor, node, isDataDisplayed, isDataRepeated, isHiddenDisplayed, editor?.current?.context])

  useEffect(() => {
    if (editor && needsWrite && !error) {
      setVersionContext({
        needsWrite: false
      })
      if (!error) {
        try {
          editor.model.enqueueChange({ isUndoable: false }, () => {
            editor.suppressedChanges = true
            editor.setRootContent(id, view)
            editor.suppressedChanges = false
          })
        } catch (e) {
          version.set({ error: e })
        }
      }
    }
  }, [editor, view, error, needsWrite])

  useEffect(() => {
    setVersionContext({ openBar: isVersionFocused ? 'breakpoint' : 'status' })
  }, [isVersionFocused])

  // @ts-ignore safari doesngt support overflow: overlay, and we disable it for cypress e2e too
  const scrollbarWidth = 20
  const getAvailableWidth = () => {
    return Math.max(900, window.innerWidth) - 300 - scrollbarWidth - (window.innerWidth < 1400 ? 24 : 48) * 2
  }

  const getReasonableBreakpoint = (width = getAvailableWidth()) => {
    const { breakpoint, version } = versionContext
    // try matching below 1400 first, unless user chose unlimited breakpoint above 1400
    var limited
    if (!(breakpoint?.props.maxWidth > 1400)) {
      limited = version.getMatchingBreakpoint(Math.min(1400, width))
    }
    return limited || version.getMatchingBreakpoint(width)
  }

  useEffect(() => {
    const listener = () =>
      setVersionContext({
        breakpoint: getReasonableBreakpoint()
      })
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  // Using String ensures that breakpointIds actually changed, and not just identity of an array (which happens on save)
  useEffect(() => {
    setVersionContext({ breakpoint: getReasonableBreakpoint() })
  }, [String(version.breakpointIds)])

  var element = getElement()
  useEffect(() => {
    if (element && !ignoreBreakpoints) {
      element.style.maxWidth = `calc(max(900px, 100vw) - 300px - ${scrollbarWidth}px - ${
        window.innerWidth < 1400 ? 24 : 48
      }px * 2)`
      element.style.minWidth = breakpoint?.props.minWidth + 'px'
      element.style.width = isFinite(breakpoint?.props.maxWidth)
        ? breakpoint?.props.maxWidth + 'px'
        : element.style.maxWidth
      element.style.display = snapshotStatus ? 'none' : ''
      element.style.outline = '0'
      element.style.outline = '1px solid var(--chakra-colors-gray-200)'
    }
  }, [editor, element, breakpoint, snapshotStatus, ignoreBreakpoints])

  if (!version) return

  return useMemo(
    () => (
      <VersionContext.Provider value={versionContextWithSetter}>
        {useSlots(
          children,
          ({ left, middle, right, editable, bottom }) => (
            <VStack
              display={isHidden ? 'none' : 'flex'}
              className={`version ${isFocused && activeVersionId == id ? 'is-focused' : ''}`}
              key={id}
              data-version-id={id}
              direction='column'
              align={'center'}
              width={'100%'}
              position='relative'
              pointerEvents={'all'}
            >
              <HStack
                width='100%'
                maxWidth={ignoreBreakpoints ? '100%' : '1400px'}
                mb={1}
                justifyContent='space-between'
              >
                {snapshotStatus && <VersionPreviewAlert />}
                <HStack className='ui name' minWidth={0} flexBasis='auto' flexGrow={0}>
                  {left || <VersionName name={name} onChange={(name) => version.rename(name)} />}
                </HStack>
                <HStack
                  justifyContent='flex-end'
                  flexBasis='auto'
                  flexGrow={0}
                  spacing={1}
                  className='ui menu'
                  height='32px'
                  color={'blackAlpha.600'}
                >
                  {middle || (
                    <HStack>
                      <BarMenu
                        isOpen={openBar == 'breakpoint'}
                        onOpen={() => setVersionContext({ openBar: 'breakpoint' })}
                        buttonProps={{
                          'aria-label': 'Breakpoint settings',
                          pointerEvents: openBar == 'breakpoint' ? 'none' : null,
                          icon: (
                            <Icon fontSize='icon-lg'>
                              <path d={mdiCellphoneLink} />
                            </Icon>
                          )
                        }}
                      >
                        <VersionBreakpointsList />
                        <VersionBreakpointsMenu />
                      </BarMenu>
                      <BarMenu
                        isOpen={openBar == 'status'}
                        onOpen={() => setVersionContext({ openBar: 'status' })}
                        buttonProps={{
                          'aria-label': 'Publication status',
                          pointerEvents: openBar == 'status' ? 'none' : null,
                          icon: (
                            <Icon fontSize='icon-lg'>
                              <path d={mdiCheckCircleOutline} />
                            </Icon>
                          )
                        }}
                      >
                        <VersionStatusList />
                      </BarMenu>
                    </HStack>
                  )}
                  <Tooltip label={`Current theme: ${theme?.details.title}`}>
                    <VersionThemeButton />
                  </Tooltip>
                  <Tooltip label={`Edit grid`}>
                    <VersionGridButton />
                  </Tooltip>
                  <VersionStatusButton />
                  <VersionMenu />
                </HStack>
              </HStack>
              <Flex
                overflow={'auto'}
                borderRadius={'3px'}
                width={snapshotStatus ? 1400 : 'min-content'}
                flexDirection='column'
                justifyItems={'center'}
                maxWidth='100%'
                outline={snapshotStatus ? '1px solid ' : null}
                outlineColor={snapshotStatus ? 'orange.500' : 'transparent'}
                transition={isVersionFocused ? 'box-shadow 0.2s 0.2s' : 'box-shadow 0.2s'}
                boxShadow={isVersionFocused ? 'lg' : 'sm'}
              >
                {error && (
                  <Alert status='error' flexDir={'column'} alignItems='flex-start'>
                    <AlertTitle>{error.name}</AlertTitle>
                    <AlertDescription>{error.message}</AlertDescription>
                  </Alert>
                )}
                <Box
                  width={'min-content'}
                  overflow={ignoreBreakpoints ? 'hidden' : 'auto'}
                  background='#fff'
                  ref={refContent}
                  pointerEvents={isVersionDeleted ? 'none' : null}
                ></Box>
                {snapshotStatus && <VersionPreview />}
              </Flex>
            </VStack>
          ),
          ...versionContextWithSetter
        )}
      </VersionContext.Provider>
    ),
    [version, error, staged?.deletedAt != null, ...Object.values(versionContext), isVersionFocused, isHidden, ...deps]
  )
}
