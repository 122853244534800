import { ImplicitBorders, onEditorCommand, onEditorHighlight } from './ChromeContext.js'
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { mdiChevronRight, mdiDotsHorizontal, mdiSync } from '@mdi/js'
import { css } from '@emotion/react'
import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { getContextIcon } from '../../../utils/element.js'
import { ContextName } from './ChromeContextParent.js'
import * as CK from '@sitecore-feaas/ckeditor5'

const ChromeBreadcrumbsChildren = ({
  context,
  onHighlight,
  onCommand
}: {
  context: CK.ModelElement
  onHighlight: onEditorHighlight
  onCommand: onEditorCommand
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const closeRef = useRef<ReturnType<typeof setTimeout>>()
  const openRef = useRef<ReturnType<typeof setTimeout>>()

  const scheduleClose = useCallback(() => {
    clearTimeout(openRef.current)
    clearTimeout(closeRef.current)
    closeRef.current = setTimeout(onClose, 200)
  }, [onClose])
  const scheduleOpen = useCallback(() => {
    clearTimeout(openRef.current)
    clearTimeout(closeRef.current)
    openRef.current = setTimeout(onOpen, 300)
  }, [onOpen])

  const open = useCallback(() => {
    clearTimeout(closeRef.current)
    onOpen()
  }, [onOpen])

  useEffect(() => {
    clearTimeout(closeRef.current)
    onClose()
  }, [context])

  const children = context
    ? (Array.from(context.getChildren()) as CK.ModelElement[]).filter((c) => c.is('element'))
    : []

  return (
    <HStack
      spacing={0}
      divider={
        <Icon color='blackAlpha.400' border='0' fontSize='icon-xs'>
          <path d={mdiChevronRight} />
        </Icon>
      }
    >
      <Button
        size='sm'
        css={ImplicitBorders}
        aria-label='Selected element'
        display='flex'
        onMouseEnter={() => onHighlight(context)}
        onMouseLeave={() => onHighlight(null)}
        leftIcon={
          <Icon boxSize='icon-lg' ml={'-8px'}>
            <path d={getContextIcon(context)} />
          </Icon>
        }
      >
        <ContextName context={context} />
      </Button>
      {children.length > 0 && (
        <Menu placement='bottom-start' offset={[-6, 10]} isLazy={true}>
          <Tooltip label='Children' placement='top'>
            <IconButton
              aria-label={'Element children'}
              icon={
                <Icon>
                  <path d={mdiDotsHorizontal} />
                </Icon>
              }
              onMouseMove={scheduleOpen}
              onMouseLeave={scheduleClose}
              onClick={open}
              as={MenuButton}
              size='sm'
              css={ImplicitBorders}
            />
          </Tooltip>
          <MenuList
            zIndex={5}
            style={{ width: 'auto', minWidth: 'auto' }}
            onMouseEnter={(isOpen && open) || null}
            onMouseLeave={(isOpen && scheduleClose) || null}
            css={css`
              pointer-events: ${isOpen ? 'all' : 'none'};
              &:before {
                position: absolute;
                margin-top: -20px;
                height: 10px;
                width: 100%;
                z-index: 1;
                content: '';
                display: block;
              }
            `}
          >
            {children.map((child, index) => {
              const subChildren = child.is('element')
                ? Array.from(child.getChildren()).filter((ch) => ch.is('element'))
                : []
              return (
                <Fragment key={index}>
                  <MenuItem
                    onMouseEnter={() => onHighlight(child)}
                    onMouseLeave={() => onHighlight(null)}
                    aria-label='Select child'
                    onClick={() => {
                      onCommand('setContext', child)
                      onClose()
                    }}
                    icon={
                      <Icon boxSize='icon-md' mr={-1}>
                        <path d={getContextIcon(child)} />
                      </Icon>
                    }
                  >
                    <ContextName context={child} />
                    {child.getAttribute?.('data-path-scope') && (
                      <Icon boxSize='icon-md' ml={2}>
                        <path d={mdiSync} />
                      </Icon>
                    )}
                    {subChildren.length > 0 && (
                      <Icon boxSize='icon-md' ml={2}>
                        <path d={mdiDotsHorizontal} />
                      </Icon>
                    )}
                  </MenuItem>
                </Fragment>
              )
            })}
          </MenuList>
        </Menu>
      )}
    </HStack>
  )
}

export default ChromeBreadcrumbsChildren
