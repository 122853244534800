import { EditorContext } from '../../../contexts/EditorContext.js'
import { useDocumentEffect } from '../../../hooks/useDocumentEffect.js'
import { isPointInsideRectangle, offsetRect } from '../../../utils/rect.js'
import {
  ChromeOrchestratorProps,
  ChromeRefs,
  EditorChromeElement,
  EditorChromeInputs,
  EditorChromeProps
} from './ChromeContext.js'
import { ChromeGrid, Grid } from './ChromeGrid.js'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { useEffect, useMemo, useRef } from 'react'

export default function ChromeOrchestratorGrid({
  props,
  context: { onChromeMeasureElement },
  refs: { memoized, pointer },
  isVisible
}: ChromeOrchestratorProps) {
  const ref = useRef<Grid>(null)
  const editor = props.editor

  const activeGridContext = memoized.context?.is('element', 'section')
    ? memoized.context
    : memoized.placed?.parent?.is('element', 'section')
    ? memoized.placed.parent
    : null

  const isPlacedGridItem = () =>
    memoized.placed?.parent?.is('element', 'section') ||
    (memoized?.context.is('element', 'section') && !memoized.placed?.parent)
  const isGridDisabled = props.popover != null

  useDocumentEffect(
    memoized.focusable,
    () => {
      props.grids.forEach((grid) => {
        grid.preventAdding = memoized.placed?.is('element', 'section')
      })
      if (!ref.current && memoized.placed && isPlacedGridItem() && memoized.placed.parent) {
        const grid = props.grids.find((grid) => grid.context == (memoized.placed.parent || memoized.context))
        const clippedPoint = grid.clipPoint(pointer)
        clippedPoint.top += 30
        grid.interact({
          anchor: {
            distance: 0,
            inside: true,
            type: 'c',
            index: memoized.placed.index,
            point: clippedPoint
          },
          point: Object.assign({}, pointer),
          type: 'moving'
        })
        grid.snapshot()

        ref.current = grid
        return () => {
          grid.finalize().commit(true)
          grid.reset()
          ref.current = null
        }
      }
    },
    [memoized.placed]
  )
  useDocumentEffect(
    memoized.focusable,
    (doc) => {
      var gridItemUnderCursor: CK.ModelElement
      var gridItemSelected: CK.ModelElement
      var timeout: ReturnType<typeof setTimeout>

      const onPointerDown = (e: PointerEvent) => {
        if (props.popover) return
        const resizing = (e.target as HTMLElement).closest('.resize-grid')?.getAttribute('data-index')
        if ((e.target as HTMLElement).closest('.ui') && !resizing) return
        if (memoized.placed && !isPlacedGridItem()) return
        if (resizing != null) {
          ref.current = props.grids[Number(resizing)]
          ref.current.interact({
            point: Object.assign({}, pointer),
            type: 'resizing'
          })
        } else {
          ref.current = props.grids.find(
            (grid) =>
              grid.outer &&
              isPointInsideRectangle(pointer, offsetRect(grid.outer, -10)) &&
              grid.interact({
                point: Object.assign({}, pointer),
                type: 'moving'
              })
          )
          if (ref.current) {
            const index = ref.current.hoveredAnchor?.index
            gridItemUnderCursor =
              index == null ? null : ((ref.current.context as CK.ModelElement).getChild(index) as CK.ModelElement)
            gridItemSelected = memoized.sectionItem
            if (
              // dont allow clicks outside of the grid area to blur the editor out
              !editor.utils
                .modelToDOMElement(ref.current.context as CK.ModelElement)
                .contains(e.target as HTMLElement) ||
              // when clicked element does not match the hovered anchor owner prevent it
              (gridItemUnderCursor &&
                !editor.utils
                  .modelToDOMElement(gridItemUnderCursor as CK.ModelElement)
                  ?.contains(e.target as HTMLElement))
            ) {
              e.preventDefault()
            }
          }
        }
      }
      const onPointerMove = (e: PointerEvent) => {
        if (!ref.current || props.popover) return
        //if ((e.target as HTMLElement).closest('.ui')) return
        const preferIndex =
          gridItemSelected?.parent == ref.current.context ? memoized.section.getChildIndex(gridItemSelected) : null
        clearTimeout(timeout)
        timeout = setTimeout(
          () => {
            const interacting = ref.current.recognize(pointer, preferIndex)
            if (interacting && ref.current.modify(pointer)) {
              const domElement = editor.utils.modelToDOMElement(ref.current.context as CK.ModelElement)
              domElement.classList.add('-grid--interactive')
              ref.current.commit(false)
              ref.current.render(false)
            }
          },
          ref.current?.interacting.type ? 1 : 32
        )
      }
      const onPointerUp = (e: PointerEvent) => {
        //if (props.popover) return
        const interactive = ref.current?.interacting.anchor
        if (ref.current?.interacting?.anchor) {
          editor.contextPlugin.supressClick()
          ref.current.finalize().commit(true)
          ref.current.render(true)
          // ensure context element does NOT change after gesture is over
          editor.execute(
            'setContext',
            editor.current.context,
            editor.contextPlugin.contains(editor.current.context, editor.current.selected)
          )
        } else {
          var target = gridItemUnderCursor
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              if (target && editor.contextPlugin.getSectionItem() != target) {
                // when clicking on borders of element, change context to that element. this allows selecting covered elements
                editor.execute('setContext', target, editor.contextPlugin.contains(target, editor.current.selected))
              }
            })
          })
        }
        if (ref.current) {
          const domElement = editor.utils.modelToDOMElement(ref.current.context as CK.ModelElement)
          domElement?.classList.remove('-grid--interactive')
          gridItemUnderCursor = null
          clearTimeout(timeout)
          ref.current.reset()
          ref.current = null
          if (interactive) {
            props.onPlacementStart(null)
          }
        }
      }
      doc.addEventListener('pointerdown', onPointerDown)
      doc.addEventListener('pointermove', onPointerMove)
      doc.addEventListener('drag', onPointerMove)
      doc.addEventListener('pointerup', onPointerUp, { capture: true })
      doc.addEventListener('dragend', onPointerUp, { capture: true })
      return () => {
        doc.removeEventListener('pointerdown', onPointerDown)
        doc.removeEventListener('pointermove', onPointerMove)
        doc.removeEventListener('drag', onPointerMove)
        doc.removeEventListener('pointerup', onPointerUp, { capture: true })
        doc.removeEventListener('dragend', onPointerUp, { capture: true })
      }
    },
    [props.grids]
  )

  useEffect(() => {
    if (memoized.context?.name == 'section') {
      editor.commands.get('input').forceDisabled('grid')
      return () => {
        editor.commands.get('input').clearForceDisabled('grid')
      }
    }
  }, [editor, memoized.context])
  return (
    <>
      {useMemo(() => {
        return props.grids.map((grid, index) => {
          return (
            <ChromeGrid
              pointer={pointer}
              index={index}
              activeIndex={
                memoized.sectionItem?.parent == grid.context
                  ? memoized.section.getChildIndex(memoized.sectionItem)
                  : null
              }
              key={`grid-${index}`}
              isVisible={isVisible}
              isActive={isVisible && activeGridContext == grid.context}
              isDisabled={isGridDisabled}
              grid={grid}
            />
          )
        })
      }, [
        JSON.stringify(props.grids.map((g) => g.getCacheChecksum())),
        isVisible,
        isGridDisabled,
        activeGridContext,
        memoized.sectionItem
      ])}
    </>
  )
}
