import * as CK from '@sitecore-feaas/ckeditor5'
import { Rect, unionRect } from '../utils/rect.js'
import { Style } from '@sitecore-feaas/sdk'

interface DragProps {}

export function useDrag(props: DragProps) {}

export type PlacementWalkStrategy =
  | 'ignore' // treat element as it doesnt exist, not allowing placement before or after
  | 'text' // allow dropping inline elements (e.g. var into text)
  | 'block' // treat element as a container for dropping
  | 'skip' // dont allow placing inside the element, only before/after
  | 'descend' // traverse children using grandparent as a context

export type PlacementStrategyGetter = (
  context: Style.Context,
  element: HTMLElement,
  parent: Placement
) => PlacementWalkStrategy

export type PlacementContextGetter = (e: HTMLElement, parent: Placement) => CK.ModelElement

export interface Placement {
  element: HTMLElement
  parent: Placement
  anchor: Placement
  context: CK.ModelElement
  style: CSSStyleDeclaration
  isHorizontal: boolean
  type: 'after' | 'inside' | 'start' | 'end' | 'unused'
  strategy: PlacementWalkStrategy
  row: Rect
  outer: Rect
  inner: Rect
  area: Rect
  edge: Rect
  gap?: Rect
  depth: number
}

function findBoundingRect(element: HTMLElement, last: boolean = false): DOMRect | null {
  function findRectRecursively(node: Node, last: boolean): DOMRect | null {
    if (node.nodeType === Node.TEXT_NODE) {
      const textNode = node as Text
      const range = document.createRange()

      for (let i = 0; i < textNode.length; i++) {
        range.setStart(textNode, last ? textNode.length - i - 1 : i)
        range.setEnd(textNode, last ? textNode.length - i : i + 1)
        const rect = range.getBoundingClientRect()
        if (rect.width > 0) {
          return rect
        }
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.nodeName.includes('SPACER')) return
      const childNodes = Array.from(node.childNodes)
      if (last) {
        childNodes.reverse()
      }

      for (const childNode of childNodes) {
        const rect = findRectRecursively(childNode, last)
        if (rect) {
          return rect
        }
      }
    }

    return null
  }

  return findRectRecursively(element, last)
}
/**
 * Function is called recursively, each call returns placement for given element and its children placement. By default
 * given element's placement is `unused` type, which means it's used only for reference. It's a duty of parent call to
 * assign the placement a specific meaning by cloning it with the required type.
 *
 * The function detects multi-row horizontal flexbox layouts and adds extra placements at the boundaries of the row.
 */
export function getHTMLElementPlacements(
  element: HTMLElement,
  getContext?: PlacementContextGetter,
  getStrategy?: PlacementStrategyGetter,
  parent?: Placement,
  children = element.children as HTMLCollection | HTMLElement[],
  extraHeight = 0,
  getBoundingClientRect?: (element: HTMLElement) => Rect
): Placement[] {
  const context = getContext ? getContext(element, parent) : null

  const style = window.getComputedStyle(element)
  const rect = getBoundingClientRect(element)
  const outer = {
    top: rect.top,
    left: rect.left,
    width: rect.width,
    height: rect.height
  }
  const inner = {
    left: outer.left + parseInt(style.paddingLeft),
    top: outer.top + parseInt(style.paddingTop),
    height: outer.height - parseInt(style.paddingTop) - parseInt(style.paddingBottom),
    width: outer.width - parseInt(style.paddingLeft) - parseInt(style.paddingRight)
  }
  const hasHorizontalLayout = style['flexDirection'] == 'row' && style['display'] == 'flex'

  const strategy = getStrategy(context, element, parent)
  const placement: Placement =
    strategy == 'descend'
      ? parent
      : {
          parent: parent,
          element: element,
          context: context,
          style: style,
          outer: outer,
          inner: inner,
          row: outer,
          area: outer,
          edge: null,
          isHorizontal: parent?.style['flexDirection'] == 'row' && parent?.style['display'] == 'flex',
          anchor: null,
          type: 'unused',
          depth: (parent?.depth ?? -1) + 1,
          strategy: strategy
        }
  if ((strategy == 'skip' || (outer.width == 0 && outer.height == 0)) && parent) return [placement]

  const placements = [] as Placement[]
  const childPlacements = []

  // Recurse to traverse children element
  if (strategy == 'block' || strategy == 'text' || strategy == 'descend') {
    for (const child of Array.from(children)) {
      if (child.tagName == 'BR' || child.tagName == 'STYLE') continue
      const nested = getHTMLElementPlacements(
        child as HTMLElement,
        getContext,
        getStrategy,
        placement,
        undefined,
        undefined,
        getBoundingClientRect
      )
      if (nested.length == 0 || nested[0].strategy == 'skip') continue

      // In case child element chose `descend` strategy (used for UL/OL wrappers for example)
      // it will act as if its children are children of the current element
      placements.push(...nested.filter((p) => p != placement))
      childPlacements.push(...nested.filter((p) => p.parent == placement))
    }
  }

  // Detect rows that element belongs to in horizontal layout
  // Does not support negative margins
  if (strategy == 'block') {
    if (hasHorizontalLayout) {
      var rowArea: Rect
      for (const current of childPlacements) {
        if (
          !rowArea ||
          (rowArea.left + rowArea.width >= current.outer.left && rowArea.top + rowArea.height <= current.outer.top)
        ) {
          rowArea = unionRect(current.outer, null)
        }
        current.row = rowArea
        Object.assign(rowArea, unionRect(current.outer, rowArea))
      }
    }
  }

  // Register first/last/between positions, but only when not flattening the placements
  if (strategy == 'block' || strategy == 'text' || strategy == 'descend') {
    var previous: Placement
    var gap: Rect
    for (const current of childPlacements) {
      if (!current.context) {
        gap = gap ? unionRect(gap, current.area) : current.area
        continue
      }
      // create placement "between" two children
      if (previous) {
        if (hasHorizontalLayout) {
          placements.push({
            ...previous,
            type: previous.row != current.row ? ('end' as const) : ('after' as const),
            anchor: previous,
            area: {
              left: previous.outer.left + previous.outer.width,
              top: previous.row.top,
              width:
                (current.row == previous.row ? current.outer.left : outer.left + outer.width) -
                (previous.outer.left + previous.outer.width),
              height: previous.row.height
            }
          })
          // In multi-row rows first element in a row gets extra between placement
          if (previous.row != current.row) {
            placements.push({
              ...previous,
              type: 'start' as const,
              anchor: previous,
              area: {
                left: outer.left,
                top: current.row.top,
                width: current.outer.left - outer.left,
                height: current.row.height
              }
            })
            placements.push({
              ...previous,
              type: 'after' as const,
              anchor: previous,
              area: {
                left: previous.row.left,
                top: previous.row.top + previous.row.height,
                width: previous.row.width,
                height: 1
              },
              isHorizontal: false
            })
          }
        } else {
          placements.push({
            ...previous,
            type: 'after' as const,
            anchor: previous,
            gap: gap,
            area: unionRect(gap, {
              left: outer.left,
              top: previous.outer.top + previous.outer.height,
              width: outer.width,
              height: current.outer.top - previous.outer.top - previous.outer.height
            })
          })
        }
        gap = null
      }
      previous = current
    }
  }

  const first = placements[0]
  const last = previous
  // Text elements only offer "insert at start/insert at end" placements.
  if (strategy == 'text') {
    const firstTextPosition = findBoundingRect(placement.element, false)
    if (firstTextPosition)
      placements.unshift({
        ...placement,
        parent: placement,
        anchor: null,
        context: null,
        element: null,
        area: findBoundingRect(placement.element, false),
        type: 'start' as const,
        isHorizontal: true
      })
    const lastTextPosition = findBoundingRect(placement.element, true)
    if (lastTextPosition)
      placements.push({
        ...placement,
        parent: placement,
        anchor: null,
        context: null,
        element: null,
        // end is the default position, so the caret is presentational here
        edge: lastTextPosition,
        type: 'end' as const,
        isHorizontal: true
      })
  } else {
    // if element has children, instead of `inside` placement it will have "start" placement instead
    if (first) {
      if (hasHorizontalLayout) {
        placements.unshift({
          ...first,
          type: 'start' as const,
          area: {
            left: outer.left,
            top: first.row.top,
            width: first.row.left - outer.left,
            height: first.row.height
          }
        })
      } else {
        placements.unshift({
          ...first,
          type: 'start' as const,
          area: {
            left: first.area.left,
            top: outer.top,
            width: first.area.width,
            height: first.area.top - outer.top
          }
        })
      }
      // create extra placement at the edges of the grid
      placements.unshift({
        ...first,
        type: 'start' as const,
        isHorizontal: false,
        area: {
          left: outer.left,
          top: outer.top - extraHeight,
          width: outer.width,
          height: first.row.top - outer.top
        }
      })
    } else if (strategy != 'ignore') {
      placements.unshift({
        ...placement,
        parent: placement,
        type: 'inside' as const,
        isHorizontal: hasHorizontalLayout
      })
    }
    if (last) {
      // horizontal layouts have two different drop points
      if (hasHorizontalLayout) {
        placements.push({
          ...last,
          anchor: last,
          type: 'end' as const,
          area: {
            left: last.outer.left + last.outer.width,
            top: last.outer.top,
            width: outer.left + outer.width - last.outer.left - last.outer.width,
            height: last.row.height
          }
        })
      }
      placements.push({
        ...last,
        type: 'end' as const,
        anchor: last,
        isHorizontal: false,
        area: {
          left: outer.left,
          top: last.row.top + last.row.height + extraHeight,
          width: outer.width,
          height: outer.top + outer.height - last.row.top - last.row.height + extraHeight
        }
      })
    }
  }
  return [placement].concat(placements)
}

export function getPlacementEdge(p: Placement) {
  const edgeArea = p.edge || p.area
  const area = {
    width: Math.max(1, edgeArea.width),
    height: Math.max(1, edgeArea.height),
    top: edgeArea.top,
    left: edgeArea.left
  }
  return p.type == 'inside'
    ? {
        width: p.isHorizontal ? 1 : p.inner.width,
        height: p.isHorizontal ? p.inner.height : 1,
        top: p.inner.top,
        left: p.inner.left
      }
    : {
        width: p.isHorizontal ? 1 : area.width,
        height: p.isHorizontal ? area.height : 1,
        top:
          area.top +
          (!p.isHorizontal
            ? p.type == 'start'
              ? area.height - 2
              : p.type == 'after' && !p.gap
              ? area.height / 2
              : 1
            : 0),
        left:
          area.left +
          (p.parent?.strategy == 'text'
            ? p.type == 'end'
              ? area.width - 1
              : 1
            : p.isHorizontal
            ? p.type == 'end'
              ? 1
              : p.type == 'after' && !p.gap
              ? area.width / 2
              : area.width - 2
            : 0)
      }
}

export interface Point {
  left: number
  top: number
}

export function distanceRectToPoint(rect: Rect, p: Point) {
  var dx = Math.max(rect.left - p.left, 0, p.left - (rect.left + rect.width))
  var dy = Math.max(rect.top - p.top, 0, p.top - (rect.top + rect.height))
  return Math.sqrt(dx * dx + dy * dy)
}

export function findEqualPlacement(target: Placement, placements: Placement[], sameDirection = false) {
  return (
    target &&
    placements.find(
      (p) =>
        (!sameDirection || p.isHorizontal == target.isHorizontal) &&
        p.type == target.type &&
        p.parent?.context == target.parent?.context &&
        p.anchor == target.anchor
    )
  )
}

export function matchPlacements(placements: Placement[], point: Point, threshold = 50) {
  const placementsWithDistance = placements
    .map((p) => [distanceRectToPoint(p.area, point), p] as [distance: number, placement: Placement])
    .sort(([d1, p1], [d2, p2]) => d1 - d2 || p2.depth - p1.depth)
  // pick closest edges within threshold
  const closeEdges = placementsWithDistance.filter(([d, p]) => d <= threshold && p.type != 'unused')
  if (closeEdges.length) {
    return closeEdges.map(([, p]) => p)
  }
  // otherwise get closest edge, within a deepmost hovered container
  return placementsWithDistance
    .filter(([d, p]) => d == 0 && p.type == 'unused')
    .sort(([, p1], [, p2]) => p2.depth - p1.depth)
    .map(([d, p]) => p)
    .map((p) => placementsWithDistance.find(([d, a]) => a.parent?.context == p.context && a.type != 'unused')?.[1])
    .filter(Boolean)
}
