import { useEffect } from 'react'

export function useDocumentEffect(element: HTMLElement, callback: (doc: Document) => void, deps: any[] = []) {
  ;[document, element?.ownerDocument].forEach((doc, index) => {
    useEffect(() => {
      if (!doc || (doc === document && index == 1)) return
      return callback(doc)
    }, [doc].concat(deps))
  })
}
