import {
  Button,
  FormControl,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  VStack
} from '@chakra-ui/react'
import { mdiClose } from '@mdi/js'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { ChangeEvent, useContext, useRef, useState } from 'react'
import { EditorContext } from '../../../contexts/EditorContext.js'
import FieldsetField from '../../FieldsetField.js'
import type { DialogAttributeGeneric } from '../dialog/Dialog.js'

export const onEditorLink = (editor: CK.Editor, commandName: string, ...args: any[]) => {
  const href: string = editor.model.document.selection.getAttribute('linkHref') as string
  const isSameLink = (value: CK.ModelTreeWalkerValue) => {
    return value.item.hasAttribute('linkHref') && value.item.getAttribute('linkHref') === href
  }
  editor.model.change((writer) => {
    if (href != null) {
      const position = editor.model.document.selection.getFirstPosition()
      const highlightStart = position.getLastMatchingPosition(isSameLink, {
        direction: 'backward',
        startPosition: position
      })
      const highlightEnd = position.getLastMatchingPosition(isSameLink)
      // can happen if selection picks up attribute somewhere from within text
      if (!highlightStart.isEqual(highlightEnd)) writer.setSelection(writer.createRange(highlightStart, highlightEnd))
    }
    editor.execute(commandName, ...args)
  })
}

export const DialogLink = ({ onNavigate }: Pick<DialogAttributeGeneric, 'onNavigate'>) => {
  const [{ editor }] = useContext(EditorContext)
  const targetName = (editor.model.document.selection.focus.parent as CK.ModelElement).name
  const target =
    targetName == 'button' || targetName == 'inline'
      ? (editor.model.document.selection.focus.parent as CK.ModelElement)
      : editor.model.document.selection
  const href: string = target.getAttribute('linkHref') as string
  const external: boolean = target.getAttribute('linkIsExternal') as boolean

  const [currentHref, setCurrentHref] = useState(href)
  const [currentExternal, setCurrentExternal] = useState(external)
  const ref = useRef()

  return (
    <VStack spacing={3} {...{ ref: ref }} alignItems='stretch' userSelect={'none'} p={'3'}>
      <FormControl>
        <InputGroup>
          <Input
            placeholder='URL to a webpage or a hosted file'
            w='320px'
            value={currentHref || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrentHref(e.target.value)}
          />
          {currentHref && (
            <InputRightElement>
              <Icon cursor={'pointer'} position='relative' pointerEvents={'all'} onClick={() => setCurrentHref('')}>
                <path d={mdiClose} />
              </Icon>
            </InputRightElement>
          )}
        </InputGroup>
      </FormControl>

      <HStack mb={3}>
        <Switch
          id='link-external'
          isChecked={currentExternal}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCurrentExternal(e.target.checked)
          }}
        />
        <FieldsetField label='Open in a new tab' htmlFor='link-external' extraProps={{ top: '1' }} />
      </HStack>

      <HStack spacing={2} justifyContent='flex-end'>
        {href && (
          <Button
            variant='subtle'
            colorScheme={'gray'}
            onClick={() => {
              onEditorLink(editor, 'unlink')
              setCurrentHref('')
              setCurrentExternal(false)
              onNavigate(null)
              editor.focus()
            }}
          >
            Remove link
          </Button>
        )}
        <Button
          variant='subtle'
          colorScheme={'purple'}
          onClick={() => {
            onNavigate(null)
            editor.focus()
          }}
        >
          Discard
        </Button>
        <Button
          variant='primary'
          onClick={() => {
            if (currentHref?.trim()) {
              onEditorLink(editor, 'link', currentHref, {
                linkIsExternal: currentExternal
              })
            } else {
              onEditorLink(editor, 'unlink')
            }
            onNavigate(null)
            editor.focus()
          }}
        >
          {href != null ? 'Update' : 'Create'}
        </Button>
      </HStack>
    </VStack>
  )
}

const linkPath =
  'M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z'

const linkItem = { icon: linkPath, label: 'Create link', id: 'link', component: DialogLink }
