import { Icon, Link } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { mdiPencilOutline } from '@mdi/js'

export default function EditLink({ children, ...props }: { children: any; [key: string]: any }) {
  return (
    <TruncatedIconLink
      rightIcon={
        <Icon ml={2}>
          <path d={mdiPencilOutline} />
        </Icon>
      }
      {...props}
    >
      {children}
    </TruncatedIconLink>
  )
}

// Link that truncates text but keeps the icon visible on the right
export function TruncatedIconLink({
  children,
  leftIcon = null,
  rightIcon = null,
  ...props
}: {
  children: any[]
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
}) {
  return (
    <Link
      {...props}
      css={css`
        display: flex;
        align-items: center;
        min-width: 0;
        color: currentColor;
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          min-width: 0px;
        }
        white-space: nowrap;
        &:not(:hover):not(:focus):not(:active) {
          svg {
            opacity: 0;
          }
        }
      `}
    >
      {leftIcon}
      <span>{children}</span>
      {rightIcon}
    </Link>
  )
}
