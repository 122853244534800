import type * as CK from '@sitecore-feaas/ckeditor5'
import { CSS, Style } from '@sitecore-feaas/sdk'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import 'setimmediate'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { useEditorBatch, useEditorStyles } from '../../../hooks/useEditorStyles.js'
import { useEditorValue } from '../../../hooks/useEditorValue.js'
import { getActiveElement } from '../../../utils/dom.js'
import {
  EditorChromeElement,
  EditorChromeInputs,
  EditorChromeProps,
  onEditorCommand,
  Placement
} from './ChromeContext.js'
import { Grid } from './ChromeGrid.js'
import ChromeOrchestrator from './ChromeOrchestrator.js'

/**
 * Chrome is a set of all UI conditions. It displays different UI configurations based on context (editor selection,
 * mosue movements and different interaction modes like drag & drop, open popovers, etc). Chrome component observes the
 * inputs, while Chrome orchestrator passes the changes down to UI conditions in a controlled manner.
 */
export default function Chrome() {
  const [editorContext, setEditorContext] = useContext(EditorContext)
  const { root, editor, isFocused, activeVersionId, isChromeVisible, onVersionMove } = editorContext
  useEffect(() => {
    if (!isChromeVisible && isFocused) {
      var timeout = setTimeout(() => {
        setEditorContext({ isChromeVisible: true })
      }, 150)
    } else if (!isFocused) {
      setEditorContext({ isChromeVisible: false })
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [isFocused])

  const batchFlushRef = useRef<ReturnType<typeof setImmediate>>()
  const onCommand = useCallback<onEditorCommand>(
    (name, ...values: any[]) => {
      onBatch(() => {
        editor.focus()
        editor.execute(name, ...values)
        console.log('Command', name, ...values)
      })

      clearImmediate(batchFlushRef.current)
      batchFlushRef.current = setImmediate(() => {
        finishBatch()
        requestAnimationFrame(() => {
          if (!document.activeElement.closest('[contenteditable], input, textarea')) {
            editor.focus()
          }
        })
      })
    },
    [editor]
  )

  /** Compute which UI elmenets need to be displayed based on inputs & measurements */
  const conditionsRef = useRef({} as Record<EditorChromeElement, boolean>)
  const propsRef = useRef({} as EditorChromeProps)
  const focusable = editor.editing.view.getDomRoot(activeVersionId) as any as HTMLElement

  const [highlight, onHighlight] = useState<CK.ModelElement>(null)
  const [placed, onPlacementStart] = useState<CK.ModelElement>(null)
  const [placement, onPlacementOver] = useState<Placement>(null)
  const [placementHighlight, onPlacementHighlight] = useState<Placement>(null)
  const contextProps: typeof editor.current = useEditorValue(editor, 'current')
  const selectionIndex: number = useEditorValue(editor.contextPlugin, 'selection')
  const sections = (
    (activeVersionId
      ? Array.from(editor.model.document.getRoot(activeVersionId)?.getChildren() || [])
      : []) as CK.ModelElement[]
  ).filter((child) => child.name == 'section')
  const [onBatch, batch, startBatch, finishBatch] = useEditorBatch(editor, contextProps.context)
  const customStyles = useEditorStyles(editor, contextProps.context)
  const gridRef = useRef([] as Grid[])
  const grids = (gridRef.current = useMemo(() => {
    return Grid.forSections(editor.stylesheet, gridRef.current, sections, customStyles).map((grid) => {
      grid.commit = function (this: typeof grid, isFinal) {
        const shouldAddElement = isFinal && this.interacting.type == 'moving' && this.interacting.anchor.type == 'add'
        if (isFinal) {
          onBatch(() => {
            editor.model.change((writer) => {
              if (shouldAddElement) {
                if (propsRef.current.placed) {
                  writer.append(propsRef.current.placed, this.context as CK.ModelElement)
                } else {
                  writer.appendElement('container', this.context as CK.ModelElement)
                }
              }
              editor.styles.setContextStyle(this.context as CK.ModelElement, {
                props: this.props,
                details: this.details,
                type: this.type
              })
            })
          })
          finishBatch()
        } else {
          if (!isFinal) {
            document.getElementById('temporary-style').textContent =
              (grid.interacting.type == 'resizing'
                ? CSS.stringify([
                    [
                      'rule',
                      '.-grid--interactive:not(#x#x) > *',
                      ['property', 'overflow', 'hidden'],
                      ['property', 'filter', 'saturate(0.2) contrast(0.5) opacity(0.34)']
                    ]
                  ])
                : '') +
              CSS.stringify([
                ['rule', '.-grid--interactive:not(#x#x)', ...CSS.produceGridProps(grid.props)],
                ['rule', '.-grid--interactive:not(#x#x) > *', ['property', 'overflow', 'hidden']]
                // while "drawing" new grid item, the new elemnet is not commited to dom,
                // have to work around to avoid changing bg
              ]).replace(/> :nth-child/g, '> :not(.-image--background):nth-child')
            // semi-hack to re-measure the grid with new settings
            focusable.dispatchEvent(new Event('feaasRefresh', { bubbles: true }))
          } else {
            document.getElementById('temporary-style').textContent = ''
          }
        }
      }
      return grid
    })
  }, [
    focusable,
    contextProps?.context?.root,
    customStyles,
    sections
      .map((s) =>
        Array.from(s.getChildren())
          .map((c) => (c as CK.ModelElement).name)
          .join('|')
      )
      .join('~')
  ]))

  const { popover, setNextPopover, registerPopover, setTriggeredPopover } = usePopoverOrchestration(
    (type: string) => conditionsRef.current[type as keyof typeof conditionsRef.current]
  )

  /** Snapshot of properties and conditions that are used as input for this tick */
  const inputsRef = useRef({} as EditorChromeInputs)
  Object.assign(inputsRef.current, {
    ...contextProps,
    root,
    placed,
    focusable,
    selectionIndex,
    highlight
  })
  const onPlacement = ({ type, anchor, context, parent: { element, context: parent } }: Placement) => {
    if (!placed) return
    if (placed.is('rootElement')) {
      onVersionMove(placed.rootName, context.is('rootElement') && type != 'start' ? context.rootName : null)
    } else {
      const oldParent = placed.parent
      const placementFocusable = element.closest('.-feaas[contenteditable="true"]')
      if (placementFocusable && getActiveElement() != placementFocusable) {
        focusable?.focus()
      }
      editor.model.change((writer) => {
        const position = anchor
          ? writer.createPositionAfter(anchor.context)
          : type == 'end' || type == 'inside'
          ? writer.createPositionAt(parent, 'end')
          : writer.createPositionAt(parent, 0)

        var inserted: CK.ModelElement | CK.ModelText = placed
        if (placed.name == 'link') {
          const text = writer.createText((placed.getChild(0) as CK.ModelText).data, placed.getAttributes() as any)
          if (position.parent.is('element') && editor.model.schema.isBlock(position.parent)) {
            inserted = text
          } else {
            inserted = writer.createElement('paragraph')
            writer.insert(text, inserted)
          }
        }
        var target = inserted

        writer.insert(inserted, position)
        /*
        if (!editor.model.schema.isObject(inserted) && editor.model.schema.isBlock(inserted))
          for (var p = inserted; p; p = p.parent as CK.ModelElement) {
            if (p == editor.current.context) return
          } */
        editor.execute('setContext', target.is('$text') ? target.parent : target, true)
        writer.setSelection(
          oldParent
            ? writer.createPositionAt(target, 'end')
            : editor.model.schema.isObject(target)
            ? writer.createRangeOn(target)
            : editor.model.schema.isLimit(target)
            ? writer.createPositionAt(target, 0)
            : target.is('element')
            ? Array.from(writer.createRangeIn(target)).some(({ item }) => !item.is('$text') && !item.is('$textProxy'))
              ? writer.createPositionAt(target, 0)
              : writer.createRangeIn(target)
            : writer.createRangeOn(target)
        )
      })
    }
    onPlacementStart(null)
    onPlacementOver(null)
  }
  useEffect(() => {
    if (!placed || !isFocused) {
      onPlacementOver(null)
      onPlacementHighlight(null)
      onPlacementStart(null)
    }
  }, [placed, isFocused])

  useEffect(() => {
    editor?.editing.view.document.on(
      'paste',
      (evt, data) => {
        if (data.dataTransfer.getData('action/scope') == 'context') {
          const html = data.dataTransfer.getData('text/html')
          const view = editor.data.processor.toView(html)
          const firstChild = view.getChild(0)
          if (firstChild?.is('element')) {
            const def =
              Style.Definitions.all.find((d) => d.htmlTagName == firstChild.name) ||
              Style.Conventions.getDefinitionFromClassName(Style.Context.getClassName(firstChild))
            const model = editor.data
              .parse(html, def?.name == 'section' ? '$root' : def.type != 'block' ? 'card' : 'section')
              ?.getChild(0)
            if (model?.is('element')) editorContext.onPlacementStart(model)
            evt.stop()
          }
        }
      },
      { priority: 'highest' }
    )
  }, [editor])

  Object.assign(propsRef.current, {
    editor,
    onCommand,
    onHighlight,
    popover,
    grids,
    customStyles,
    placement: placementHighlight || placement,
    onPlacementHighlight,
    onPlacementOver,
    onPlacementStart,
    onPlacement,
    onPopoverOpen: setTriggeredPopover,
    onPopoverClose: setNextPopover,
    onPopoverRegister: registerPopover,
    ...inputsRef.current
  })

  useEffect(() => {
    onHighlight(null)
    setNextPopover(null)
  }, [editor.contextPlugin.context])

  useEffect(() => {
    editorContext.onPlacementStart = onPlacementStart
  }, [])

  return (
    <div
      className={`chrome ${isChromeVisible ? 'ui ui-visible' : 'ui'}`}
      style={{
        position: 'absolute',
        zIndex: 10,
        top: 0,
        left: 0,
        pointerEvents: 'none'
      }}
    >
      <ChromeOrchestrator
        context={editorContext}
        inputs={inputsRef.current}
        props={propsRef.current}
        conditions={conditionsRef.current}
      />
      <style id='temporary-style'></style>
    </div>
  )
}

function usePopoverOrchestration(canOpenToolbar: (type: string) => boolean) {
  const [popover, setPopover] = useState<string>(null)

  const setNextPopover = useCallback((closingPopover?: string) => {
    setPopover((popover) => {
      if (closingPopover && closingPopover != popover) {
        return popover
      }
      var nextPopover = null
      for (const type of ['variable', 'range'] as const) {
        if (canOpenToolbar(type) && closingPopover != type) {
          nextPopover = type
          break
        } else if (popover == type) {
          nextPopover = null
        }
      }
      if ((popover && nextPopover != popover) || closingPopover == popover) {
        openPopovers.current[popover]?.()
        openPopovers.current[popover] = null
      }
      return nextPopover
    })
  }, [])

  const openPopovers = useRef<Record<string, any>>({})
  const registerPopover = (name: string, callback: any) => {
    openPopovers.current[name] = callback
  }

  const setTriggeredPopover = useCallback((nextPopover: string) => {
    setPopover((popover) => {
      if (popover && nextPopover != popover) {
        openPopovers.current[popover]?.()
        openPopovers.current[popover] = null
      }
      return nextPopover
    })
  }, [])

  return { popover, setNextPopover, registerPopover, setTriggeredPopover }
}
