import { ButtonGroup, Divider, Icon, IconButton, Tooltip } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { mdiArrowAll, mdiArrowExpandAll, mdiDragVertical } from '@mdi/js'
import { useRef, useState } from 'react'
import { EditorChromeProps, ImplicitBorders } from './ChromeContext.js'
import ChromeContextActions from './ChromeContextActions.js'
import ChromeContextAdd from './ChromeContextAdd.js'
import ChromeContextType from './ChromeContextType.js'

export default function ChromeContextToolbar(props: EditorChromeProps) {
  const { editor, placed, context, onPlacementStart } = props
  const [isDragging, setDragging] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const isDraggable = !editor.isOnlySection(context)
  return (
    <>
      <canvas
        style={{
          width: 1,
          height: 1,
          opacity: 0,
          position: 'fixed'
        }}
        ref={canvasRef}
      ></canvas>
      <ButtonGroup
        spacing={'4px'}
        css={css`
          padding: 4px 3px 4px 3px;
          margin-top: -40px;
          background: #fff;
          position: absolute;
          align-items: center;
          display: inline-flex;
          border: 1px solid #94949d;
          border-radius: 4px;
          height: 32px;

          > hr {
            height: 24px;
            border-color: #e4e4e7;
          }
        `}
        color='rgba(0,0,0,0.68)'
      >
        <Tooltip label='Reposition element' placement='top' isDisabled={!isDraggable}>
          <IconButton
            onDragStartCapture={(e) => {
              e.dataTransfer.setData('text/plain', '')
              e.dataTransfer.effectAllowed = 'all'
              e.dataTransfer.setDragImage(canvasRef.current, 0, 0)
              onPlacementStart(context)
              setDragging(true)
            }}
            onDragEnd={(e) => {
              if (e.dataTransfer.dropEffect == 'none') {
                onPlacementStart(null)
                setDragging(false)
              }
            }}
            onClick={() => {
              onPlacementStart(context)
            }}
            variant={placed ? 'secondary' : 'minimal'}
            draggable={true}
            aria-label='Move element'
            isDisabled={!isDraggable}
            icon={
              <Icon boxSize='icon-lg'>
                <path d={mdiArrowAll} />
              </Icon>
            }
            css={ImplicitBorders}
          />
        </Tooltip>
        <Divider orientation='vertical' />
        <ChromeContextType {...props} />
        <Divider orientation='vertical' />
        <ChromeContextActions {...props} />
        <ChromeContextAdd {...props} />
      </ButtonGroup>
    </>
  )
}
