import { Box, Button, Icon, Popover, PopoverContent, PopoverTrigger, Tooltip } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { mdiFormatPilcrow } from '@mdi/js'
import { Style } from '@sitecore-feaas/sdk'
import { EditorChromeProps } from './ChromeContext.js'
import ChromeElementTypes from './ChromeElementTypes.js'

const MenuButtonStyle = css`
  background: #fff;
  padding: 0;
  border: 1px solid #94949d;
  color: rgba(0, 0, 0, 0.68);
  height: 30px;
  width: 30px;

  &:hover,
  &[data-active] {
    background: #fff;
  }

  svg {
    height: 18px;
    width: 18px;
    margin-top: -1px;
  }
`

export default function ChromeBlockToolbar({
  editor,
  block,
  onCommand,
  onPopoverClose,
  onPopoverOpen
}: EditorChromeProps) {
  const classList = Style.Context.getClassList(block)
  const genericClassName = Style.ClassList.getElementGenericClassName(classList)
  const category = Style.Conventions.getElementType(genericClassName)

  return (
    <Popover
      isLazy={true}
      flip={false}
      onOpen={() => onPopoverOpen('block')}
      onClose={() => onPopoverClose('block')}
      placement='bottom-start'
    >
      <PopoverTrigger aria-label='Block type menu'>
        <Box>
          <Tooltip label='Pick text block type' placement='top-start'>
            <Button css={MenuButtonStyle} variant={'minimal'} minWidth={0}>
              <Icon>
                <path d={mdiFormatPilcrow} />
              </Icon>
            </Button>
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <ChromeElementTypes
          value={genericClassName}
          category={category}
          rules={editor.stylesheet.rules}
          onChange={(definition) => {
            editor.styles.setContextName(block, definition.type, definition.name)
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
