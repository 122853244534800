import { BoxProps, HStack, Icon } from '@chakra-ui/react'
import { mdiChevronRight } from '@mdi/js'
import VersionStatusBadge from './VersionStatusBadge.js'

export default function VersionStatusList(props?: BoxProps) {
  return (
    <HStack
      className='status'
      alignContent='center'
      flexBasis={'auto'}
      flexGrow={1}
      justifyContent={'flex-end'}
      divider={
        <Icon color='blackAlpha.400' border='0' fontSize='icon-lg'>
          <path d={mdiChevronRight} />
        </Icon>
      }
      {...props}
    >
      <VersionStatusBadge status='draft' label='Draft' />
      <VersionStatusBadge status='staged' label='Staged' />
      <VersionStatusBadge status='published' label='Published' />
    </HStack>
  )
}
