import { Icon, IconButton } from '@chakra-ui/react'
import { mdiViewDashboardOutline } from '@mdi/js'
import { forwardRef, useContext } from 'react'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { VersionContext } from '../../../contexts/VersionContext.js'

export const VersionGridButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const [
    {
      version: { id }
    }
  ] = useContext(VersionContext)
  const [{ editor, isFocused, setActiveVersionId, sidebarMode, sidebarDialog }, setEditorContext] =
    useContext(EditorContext)
  return (
    <IconButton
      ref={ref}
      {...props}
      size='xs'
      isActive={isFocused && sidebarMode == 'context' && sidebarDialog == 'grid'}
      variant={'chrome'}
      colorScheme='primary'
      onClick={() => {
        setActiveVersionId(id)
        const section =
          editor?.current.context.root.rootName == id
            ? editor?.current.section
            : editor.getFirstSection(editor?.model.document.getRoot(id))
        editor.execute('setContext', section)
        requestAnimationFrame(() => {
          setEditorContext({
            sidebarMode: 'context',
            sidebarDialog: 'grid'
          })
        })
      }}
      aria-label='Edit grid'
      icon={
        <Icon fontSize='icon-lg'>
          <path d={mdiViewDashboardOutline} />
        </Icon>
      }
    />
  )
})

export default VersionGridButton
