import { Badge, Icon, Tooltip } from '@chakra-ui/react'
import { mdiEyeOutline } from '@mdi/js'
import { Version } from '@sitecore-feaas/sdk'
import { useContext } from 'react'
import { VersionContext } from '../../../contexts/VersionContext.js'
const VersionStatusBadge = ({ status, label }: { status: Version['status']; label: string }) => {
  const [{ snapshotStatus, version }, setContext] = useContext(VersionContext)
  const head = version.getCurrent()
  const current = version.getCurrent(status == 'draft' ? ['saved', 'draft'] : status)
  const isBehind = current?.revision < head.revision

  let isSelected = (snapshotStatus ? version.getCurrent(snapshotStatus) : head) == current

  let color: string = null
  let text: string = null
  let tooltip = null
  let isDisabled: boolean = null
  let handleClick = () => {
    setContext({ snapshotStatus: status })
  }
  if (current == null) {
    isDisabled = true
    color = 'transparent'
    text = `Never ${label.toLowerCase()}`
  } else {
    if (current.deletedAt) {
      color = 'red'
      text = status == 'draft' ? 'Deleted' : 'Un' + label.toLowerCase()
      handleClick = () => {
        setContext({ snapshotStatus: null })
      }
    } else {
      if (isBehind) {
        color = 'orange'
        text = `Previously ${label.toLowerCase()}`
        if (!isSelected && !isDisabled) tooltip = 'Display snapshot'
      } else {
        handleClick = () => {
          setContext({ snapshotStatus: null })
        }
        color = 'teal'
        text = label
        if (isSelected) {
          tooltip = 'Current status'
        } else if (!isDisabled && isBehind) {
          tooltip = 'Switch back to editing'
        }
      }
    }
  }
  return (
    <Tooltip label={tooltip} placement='top'>
      <Badge
        borderRadius={'100px'}
        className='status-badge'
        variant={'clickable'}
        size={'sm'}
        pointerEvents={isSelected || isDisabled || (isSelected && !isBehind) ? 'none' : null}
        selected={isSelected}
        display='flex'
        alignItems={'center'}
        colorScheme={color}
        onClick={isDisabled ? null : handleClick}
      >
        {isSelected && (
          <Icon mr={1} boxSize='icon-sm'>
            <path d={mdiEyeOutline} />
          </Icon>
        )}
        {text}
      </Badge>
    </Tooltip>
  )
}

export default VersionStatusBadge
