import {
  Button,
  Box,
  ButtonProps,
  HStack,
  IconButton,
  IconButtonProps,
  StackProps,
  Tooltip,
  UseDisclosureProps,
  useDisclosure
} from '@chakra-ui/react'
import { PropsWithChildren, useRef } from 'react'

export default function BarMenu({
  children,
  buttonProps,
  ...props
}: PropsWithChildren<{ buttonProps?: IconButtonProps } & StackProps & UseDisclosureProps>) {
  const { isOpen, getButtonProps } = useDisclosure(props)
  const ref = useRef<HTMLDivElement>()
  const position = isOpen && window.innerWidth < 1100 ? 'absolute' : 'static'
  const { isOpen: isOpenGiven, onClose, onOpen, ...extraProps } = props

  return (
    <>
      <HStack
        background='gray.50'
        ref={ref}
        outline={isOpen && position != 'absolute' ? '1px solid' : '0px'}
        outlineColor={'gray.200'}
        borderRadius={'100px'}
        position='relative'
        p='1px 2px'
        spacing={isOpen ? '4px' : null}
        {...extraProps}
      >
        <HStack
          position={position}
          bottom={'100%'}
          p={position == 'absolute' ? '2px 2px' : ''}
          m={position == 'absolute' ? '-2px -2px' : ''}
          borderRadius={'100px'}
          transform={position == 'absolute' ? 'translate(-50%, -10px)' : '0px'}
          outline={position == 'absolute' ? '1px solid' : '0px'}
          outlineColor={'gray.200'}
          background='gray.50'
          boxShadow={position == 'absolute' ? 'base' : ''}
        >
          {isOpen && children}
        </HStack>

        <Tooltip label={buttonProps['aria-label']}>
          <IconButton
            variant='chrome'
            colorScheme='purple'
            isActive={isOpen}
            aria-label={buttonProps['aria-label']}
            size='xs'
            {...buttonProps}
            {...getButtonProps()}
            tabIndex={-1}
          />
        </Tooltip>
      </HStack>
    </>
  )
}
