import {
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Text
} from '@chakra-ui/react'
import { mdiArrowRight, mdiAxisXArrow, mdiPlus, mdiSwapHorizontal } from '@mdi/js'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { Style } from '@sitecore-feaas/sdk'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useDocumentEffect } from '../../../hooks/useDocumentEffect.js'
import { getContextIcon } from '../../../utils/element.js'
import { distanceRectToPoint, findEqualPlacement, Placement } from '../../../utils/placement.js'
import { getRectStyle, Rect } from '../../../utils/rect.js'
import { ChromeRefs, EditorChromeProps } from './ChromeContext.js'

export default function ChromePlacementToolbar({
  placement,
  placed,
  refs,
  onChromeMeasureElement,
  ...props
}: {
  onChromeMeasureElement: (element: HTMLElement, rect?: Rect) => Rect
  refs: ChromeRefs
  placement: Placement
} & EditorChromeProps) {
  const { onPopoverOpen, onPopoverClose, onPlacementHighlight, onPlacement } = props

  const [anchoredPlacement, setAnchoredPlacement] = useState<Placement>(null)
  const [usedPlacements, setUsedPlacements] = useState<Placement[]>([])
  const targetRef = useRef<HTMLButtonElement>(null)
  const iconRef = useRef<HTMLDivElement>(null)
  const mouseXY = useRef<{ left: number; top: number }>({ left: 0, top: 0 })

  const reposition = useCallback(() => {
    if (refs.memoized.placed && !anchoredPlacement) {
      const rect = { ...mouseXY.current, width: 24, height: 24 }
      Object.assign(
        targetRef.current.style,
        getRectStyle({
          ...rect,
          top: rect.top - 12,
          left: rect.left - 12
        })
      )
      Object.assign(
        iconRef.current.style,
        getRectStyle({
          ...rect,
          top: rect.top + 6,
          left: rect.left + 12
        })
      )
    }
  }, [refs.memoized.placed, anchoredPlacement])

  useEffect(() => {
    if (!refs.memoized.placed) return
    document.body.style.cursor = 'crosshair'
    return () => {
      document.body.style.cursor = ''
    }
  }, [refs.memoized.placed])

  useDocumentEffect(
    refs.memoized.focusable,
    (doc) => {
      const onMove = (e: MouseEvent) => {
        if (mouseXY.current.left == refs.pointer.left && mouseXY.current.top == refs.pointer.top) {
          return
        }
        Object.assign(mouseXY.current, refs.pointer)
        reposition()
      }
      doc.addEventListener('drag', onMove, { capture: true })
      doc.addEventListener('mousemove', onMove, { capture: true })
      return () => {
        doc.removeEventListener('drag', onMove, { capture: true })
        doc.removeEventListener('mousemove', onMove, { capture: true })
      }
    },
    [reposition]
  )

  useEffect(reposition, [reposition, placement, placed, anchoredPlacement, targetRef.current])

  // put inititating placement on top, sort others, removes dupes
  const sortedPlacements = [anchoredPlacement]
    .concat(
      usedPlacements
        .filter((p) => p && p != anchoredPlacement)
        .sort((a, b) => {
          return distanceRectToPoint(a.area, { left: 0, top: 0 }) - distanceRectToPoint(b.area, { left: 0, top: 0 })
        })
    )
    .map((p) => findEqualPlacement(p, refs.placements) || p)
    .filter((v, i, a) => v && a.indexOf(v) === i)

  useEffect(() => {
    if (!placed) {
      setAnchoredPlacement(null)
    }
  }, [placed])

  // Icon with shadow has 10px outline border because otherwise Safari leaves ugly glitch trace
  return (
    <>
      <Box
        ref={iconRef}
        pointerEvents={'none'}
        height='46px'
        width='46px'
        marginTop='-10px'
        marginLeft='-10px'
        position={'absolute'}
        border={'10px solid rgba(0,0,0,0.001)'}
        zIndex={10}
      >
        <Icon
          boxSize='icon-xl'
          p={1}
          background={'primary.100'}
          color='primary.500'
          borderRadius='3px'
          opacity={!anchoredPlacement && placed ? 1 : 0}
          boxShadow='md'
        >
          <path d={placed ? getContextIcon(placed) : ''} />
        </Icon>
      </Box>
      <Menu
        isLazy={true}
        onOpen={() => {
          setUsedPlacements(refs.matchedPlacements)
          setAnchoredPlacement(placement)
          onPopoverOpen('placement')
        }}
        offset={[-20, 40]}
        onClose={() => {
          setAnchoredPlacement(null)
          onPlacementHighlight(null)
          onPopoverClose('placement')
        }}
      >
        {({ isOpen }) => (
          <>
            {/* Dropping into the button prevents the other drop handler */}
            <MenuButton
              ref={targetRef}
              pointerEvents={placement ? 'all' : 'none'}
              width={6}
              height={6}
              position='absolute'
              zIndex={6}
              borderRadius={3}
              id='drop-target'
              onClick={(e) => {
                if (refs.matchedPlacements.length == 1) {
                  onPlacement(placement)
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              onDropCapture={(e) => {
                if (placed) {
                  e.dataTransfer.dropEffect = 'move'
                  e.preventDefault()
                  e.stopPropagation()
                  targetRef.current?.click()
                }
              }}
              cursor={'crosshair'}
              as={IconButton}
              slot='button'
              transition={'opacity 0.15s'}
              variant={'primary'}
              size='xs'
              opacity={!placement || !placed || isOpen ? 0 : 0.5}
              p={0}
              icon={
                <Icon boxSize='icon-md'>
                  <path d={mdiPlus} />
                </Icon>
              }
              aria-label='Add element here'
            ></MenuButton>
            <MenuList zIndex={10} onMouseLeave={(e) => onPlacementHighlight(null)}>
              <MenuOptionGroup title='Select destination'>
                {sortedPlacements.map((placement, index) => {
                  const { type, parent, anchor } = placement
                  return (
                    <Fragment key={index}>
                      {index == 1 ? <MenuDivider my={0} pointerEvents='none' mt='-1px' /> : null}
                      <MenuItem
                        key={index}
                        onMouseEnter={() => {
                          onPlacementHighlight(placement)
                        }}
                        onClick={() => onPlacement(placement)}
                        icon={
                          <Icon boxSize='icon-md'>
                            <path d={getContextIcon(parent.context as CK.ModelElement)} />
                          </Icon>
                        }
                      >
                        <Flex align='center'>
                          {Style.Context.getContextLabel(parent.context)}
                          <Text color='gray.400' as='span' ml='1'>
                            {type == 'start' && <>At the start</>}
                            {type == 'end' && <>At the end</>}
                            {type == 'inside' && <>Inside</>}
                            {type == 'after' && (
                              <>After {Style.Context.getContextLabel(anchor.context).toLowerCase()}</>
                            )}
                          </Text>
                          {placement.isHorizontal && (
                            <Icon ml={'auto'}>
                              <path d={mdiSwapHorizontal} />
                            </Icon>
                          )}
                        </Flex>
                      </MenuItem>
                    </Fragment>
                  )
                })}
              </MenuOptionGroup>
            </MenuList>
          </>
        )}
      </Menu>
    </>
  )
}
