import { Divider, HStack, Icon, IconButton } from '@chakra-ui/react'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { onEditorCommand, onEditorHighlight } from './ChromeContext.js'
import { mdiFileTree } from '@mdi/js'
import ChromeBreadcrumbsChildren from './ChromeBreadcrumbsChildren.js'
import ChromeBreadcrumbsParents from './ChromeBreadcrumbsParents.js'

const ChromeBreadcrumbs = ({
  context,
  onHighlight,
  onCommand
}: {
  context: CK.ModelElement
  onHighlight: onEditorHighlight
  onCommand: onEditorCommand
}) => {
  return (
    <div style={{ position: 'sticky', pointerEvents: 'all', bottom: 0, marginBottom: '-32px' }}>
      <HStack
        spacing={'4px'}
        margin={'0 -1px'}
        padding={'4px 3px 4px 3px'}
        background={'#fff'}
        border={'1px solid #94949d'}
        borderRadius={'4px'}
        height={'32px'}
        color={'rgba(0, 0, 0, 0.68)'}
      >
        <ChromeBreadcrumbsParents context={context} onHighlight={onHighlight} onCommand={onCommand} />
        <ChromeBreadcrumbsChildren context={context} onHighlight={onHighlight} onCommand={onCommand} />
      </HStack>
    </div>
  )
}

export default ChromeBreadcrumbs
