import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { mdiChevronDown, mdiMagnify } from '@mdi/js'
import { ChangeEvent, useContext } from 'react'
import { EditorContext } from '../../../contexts/EditorContext.js'

export function EditorUIMode(
  props: Partial<
    { size?: ButtonProps['size'] } & Pick<EditorContext, 'isDataDisplayed' | 'isDataRepeated' | 'isHiddenDisplayed'>
  >
) {
  const [editorContext, setEditorContext] = useContext(EditorContext)
  const { size, isDataDisplayed, isDataRepeated, isHiddenDisplayed } = { ...editorContext, ...props }
  const isPreview = isDataDisplayed && isDataRepeated && !isHiddenDisplayed
  return (
    <Popover placement='bottom-start' closeOnBlur={true} isLazy={true}>
      <PopoverTrigger>
        {size == 'sm' ? (
          <Box boxSize='8'>
            <Tooltip label='Preview options'>
              <IconButton
                aria-label='Preview options'
                boxSize={8}
                color={!isPreview ? 'primary.500' : undefined}
                minW={8}
                icon={
                  <Icon boxSize='icon-xl'>
                    <path d={isPreview ? mdiMagnify : mdiMagnify} />
                  </Icon>
                }
              />
            </Tooltip>
          </Box>
        ) : (
          <Button
            borderRadius={4}
            size={size}
            w={size ? 'auto' : '111px'}
            tabIndex={0}
            variant='secondary'
            rightIcon={
              <Icon boxSize='icon-xl' mr={-2}>
                <path d={mdiChevronDown} />
              </Icon>
            }
          >
            {isPreview ? 'Preview' : 'Inspect'}
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent>
        <VStack align={'stretch'} p={4} spacing={4} className='ui'>
          <HStack as='label'>
            <Switch
              isChecked={isDataDisplayed}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEditorContext({ isDataDisplayed: e.target.checked })}
            />
            <Text fontSize='12px'>Display mapped data</Text>
          </HStack>
          <HStack as='label'>
            <Switch
              isChecked={isDataRepeated}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEditorContext({ isDataRepeated: e.target.checked })}
            />
            <Text fontSize='12px'>Show all data collections</Text>
          </HStack>
          <HStack as='label'>
            <Switch
              isChecked={!isHiddenDisplayed}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEditorContext({ isHiddenDisplayed: !e.target.checked })
              }
            />
            <Text fontSize='12px'>Hide hidden elements</Text>
          </HStack>
        </VStack>
      </PopoverContent>
    </Popover>
  )
}
