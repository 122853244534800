import {
  ButtonProps,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import {
  mdiArchiveOutline,
  mdiArrowAll,
  mdiCodeTags,
  mdiContentCopy,
  mdiDotsHorizontal,
  mdiInformationOutline,
  mdiPublish,
  mdiPublishOff,
  mdiTrashCanOutline,
  mdiUndoVariant
} from '@mdi/js'
import React, { useContext } from 'react'
import 'scroll-shadow-element'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { VersionContext, commitVersionData, saveVersionData } from '../../../contexts/VersionContext.js'
import { ConfirmationContext } from '../../providers/ConfirmationProvider.js'
import VersionEmbed from './VersionEmbed.js'
import useStylesUpToDateConfirmationAction from '../../../hooks/useStylesUpToDateConfirmationAction.js'
import { Link } from '@chakra-ui/react'

export default function VersionMenu(props?: ButtonProps) {
  const [{ editor, focus, onPlacementStart }] = useContext(EditorContext)
  const [{ snapshotStatus, version }, setVersionContext] = useContext(VersionContext)
  const { setConfirm } = useContext(ConfirmationContext)
  const { children, ...properties } = props
  const toast = useToast()

  const isDeleted = version.isDeleted()
  const lastPushed = version.getLastPushed()
  const staged = version.getCurrent('staged')
  const published = version.getCurrent('published')

  const handleStage = useStylesUpToDateConfirmationAction('stage', version, editor)
  const handlePublish = useStylesUpToDateConfirmationAction('publish', version, editor)

  return (
    <Menu placement='bottom-end' isLazy={true}>
      {({ isOpen }) => (
        <>
          {!version.isDeleted() && (
            <Tooltip label='More actions' placement='top'>
              <MenuButton
                aria-label='More actions'
                className='version-actions'
                color='blackAlpha.600'
                size='sm'
                height={8}
                width={8}
                isActive={isOpen}
                as={IconButton}
                icon={
                  <Icon boxSize='icon-xl'>
                    <path d={mdiDotsHorizontal} />
                  </Icon>
                }
                opacity={snapshotStatus ? 0 : 1}
                isDisabled={snapshotStatus}
                {...props}
              />
            </Tooltip>
          )}
          <MenuList color='gray.600' zIndex='30'>
            {children || (
              <>
                <MenuItem
                  icon={
                    <Icon boxSize='6'>
                      <path d={mdiContentCopy} />
                    </Icon>
                  }
                  onClick={() => {
                    focus(commitVersionData(editor, version)?.duplicate().id)
                  }}
                >
                  Duplicate version
                </MenuItem>
                {isDeleted && (
                  <MenuItem
                    onClick={() => version.restore()}
                    icon={
                      <Icon boxSize='6' mt='-2px'>
                        <path d={mdiUndoVariant} />
                      </Icon>
                    }
                  >
                    {lastPushed ? 'Unarchive' : 'Undelete'}
                  </MenuItem>
                )}
                <MenuDivider />
                {!isDeleted && (
                  <MenuItem
                    icon={
                      <Icon boxSize='6'>
                        <path d={mdiArrowAll} />
                      </Icon>
                    }
                    onClick={() => {
                      focus(version.id)
                      setTimeout(() => {
                        onPlacementStart(editor.model.document.getRoot(version.id))
                      }, 100)
                    }}
                  >
                    Reorder version
                  </MenuItem>
                )}
                <MenuItem
                  isDisabled={isDeleted}
                  onClick={() => {
                    commitVersionData(editor, version)
                    setConfirm({
                      type: 'modal',
                      title: 'Embedding code',
                      body: <VersionEmbed componentId={version.componentId} />,
                      button: (
                        <>
                          <Icon boxSize='icon-xl' mr='2'>
                            <path d={mdiContentCopy} />
                          </Icon>
                          Copy code
                        </>
                      ),
                      action: (e) => {
                        toast({
                          duration: 4000,
                          status: 'success',
                          title: 'Code has been copied',
                          description: 'Paste it into your app now'
                        })
                        navigator.clipboard.writeText(document.querySelector('#generated-code').textContent)
                        e.preventDefault()
                      }
                    })
                  }}
                  icon={
                    <Icon boxSize='6' mt='-2px'>
                      <path d={mdiCodeTags} />
                    </Icon>
                  }
                >
                  Get embedding code
                </MenuItem>

                {isDeleted && (
                  <MenuItem
                    onClick={() => version.restore()}
                    icon={
                      <Icon boxSize='6' mt='-2px'>
                        <path d={mdiUndoVariant} />
                      </Icon>
                    }
                  >
                    {lastPushed ? 'Unarchive' : 'Undelete'}
                  </MenuItem>
                )}
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    handleStage()
                  }}
                  isDisabled={isDeleted || !version.needsStaging()}
                  icon={
                    <Icon boxSize='6' mt='-2px'>
                      <path d={mdiPublish} />
                    </Icon>
                  }
                >
                  {staged ? 'Restage' : 'Stage'}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const publish = () => {
                      handlePublish()
                    }
                    if (published) {
                      setConfirm({
                        action: publish,
                        title: 'Publishing new version of version',
                        button: published ? 'Republish' : 'Publish',
                        body: `Click ${
                          published ? 'Republish' : 'Publish'
                        } to make this updated version available on the live website`
                      })
                    } else {
                      publish()
                    }
                  }}
                  isDisabled={isDeleted || !version.needsPublishing()}
                  icon={
                    <Icon boxSize='6' mt='-2px'>
                      <path d={mdiPublish} />
                    </Icon>
                  }
                >
                  {published ? 'Republish' : 'Publish'}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() =>
                    setConfirm({
                      variant: 'danger',
                      action: () => {
                        version.unstage()
                      },
                      title: 'Unstaging version',
                      button: 'Unstage',
                      body: 'Version will be available on public-facing pages of the website. You won’t be able to add version to a new bundle.'
                    })
                  }
                  isDisabled={!staged || !!staged?.deletedAt}
                  icon={
                    <Icon boxSize='6' mt='-2px'>
                      <path d={mdiPublishOff} />
                    </Icon>
                  }
                >
                  Unstage
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const bundles = version.component
                      .getBundles()
                      .filter((bundle) =>
                        Object.keys(
                          bundle.getVersionMap(
                            bundle.component.getBundlableVersions(bundle.component.getVersions(['staged', 'published']))
                          )
                        ).includes(version.id)
                      )
                    setConfirm({
                      variant: 'danger',
                      action: () => version.unpublish(),
                      title: 'DANGER: Unpublishing version',
                      button: 'Unpublish',
                      body: (
                        <Text>
                          Version will disappear from public-facing pages of the website.
                          <br />
                          {bundles.length > 0 && (
                            <>
                              Version in these bundles will be replaced with:
                              <ul style={{ marginLeft: '26px' }}>
                                {bundles.map((bundle) => (
                                  <li>
                                    In bundle {bundle.name}, <b>{version.name}</b> version will be replaced with
                                    auto-assigned version
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </Text>
                      )
                    })
                  }}
                  isDisabled={!published || !!published?.deletedAt}
                  icon={
                    <Icon boxSize='6' mt='-2px'>
                      <path d={mdiPublishOff} />
                    </Icon>
                  }
                >
                  Unpublish
                </MenuItem>
                {!isDeleted && (
                  <MenuItem
                    onClick={() => {
                      if (staged && !staged.deletedAt) version.unstage()
                      version.archive()
                    }}
                    isDisabled={!!isDeleted}
                    icon={
                      lastPushed ? (
                        <Icon boxSize='6' mt='-2px'>
                          <path d={mdiArchiveOutline} />
                        </Icon>
                      ) : (
                        <Icon boxSize='6' mt='-2px'>
                          <path d={mdiTrashCanOutline} />
                        </Icon>
                      )
                    }
                  >
                    {lastPushed ? 'Archive' : 'Delete'}
                  </MenuItem>
                )}
                <MenuDivider />

                <MenuItem
                  as={'a'}
                  target='_blank'
                  href='https://doc.sitecore.com/xmc/en/users/xm-cloud/staging-and-publishing-components.html'
                  icon={
                    <Icon boxSize='6' mt='-2px'>
                      <path d={mdiInformationOutline} />
                    </Icon>
                  }
                >
                  Learn more about publishing
                </MenuItem>
              </>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  )
}
