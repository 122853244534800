import { Alert, AlertDescription, AlertIcon, Button, ButtonGroup, CloseButton, Icon, Tooltip } from '@chakra-ui/react'
import { mdiRestore } from '@mdi/js'
import { useContext } from 'react'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { commitVersionData, VersionContext } from '../../../contexts/VersionContext.js'

export default function VersionPreviewAlert() {
  const [{ editor }] = useContext(EditorContext)
  const [{ snapshotStatus, version }, setVersionContext] = useContext(VersionContext)
  return (
    <Alert status='warning' position='absolute' zIndex={2} width='auto' mt={'-2px'} borderRadius='5px 5px 0 0'>
      <AlertIcon />
      <AlertDescription marginRight={'0 !important'}>Snapshot of a {snapshotStatus} revision</AlertDescription>
      <ButtonGroup>
        <Button
          variant='outline'
          size='sm'
          ml={3}
          colorScheme='blackAlpha'
          leftIcon={
            <Icon>
              <path d={mdiRestore} />
            </Icon>
          }
          onMouseDown={() => {
            commitVersionData(editor, version)?.revertTo(version.getCurrent(snapshotStatus))
            setVersionContext({
              needsWrite: true,
              snapshotStatus: null
            })
          }}
        >
          Revert
        </Button>
        <Tooltip label='Return to draft' placement='top'>
          <CloseButton
            alignSelf='flex-end'
            size='lg'
            boxSize={8}
            onClick={() => {
              setVersionContext({
                snapshotStatus: null
              })
            }}
          />
        </Tooltip>
      </ButtonGroup>
    </Alert>
  )
}
