import { Icon, Badge, Tooltip, BadgeProps } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Style } from '@sitecore-feaas/sdk'
import React from 'react'
import { mdiEyeOutline } from '@mdi/js'

const VersionBreakpointBadge = ({
  isSelected,
  breakpoint,
  onClick,
  children,
  ...props
}: {
  isSelected?: boolean
  breakpoint?: Style.Rule<'breakpoint'>
  children?: any
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
} & BadgeProps) => {
  return (
    <Tooltip
      placement='top'
      label={
        onClick &&
        `Preview at ${!isFinite(breakpoint?.props.maxWidth) ? 'max size' : breakpoint?.props.maxWidth + 1 + 'px'}`
      }
    >
      <Badge
        className={'size-breakpoint'}
        colorScheme={isSelected ? 'primary' : 'blackAlpha'}
        tabIndex={0}
        borderRadius={'99px'}
        variant={'clickable'}
        onClick={onClick}
        {...props}
      >
        {isSelected && (
          <Icon mr={1} boxSize='icon-sm'>
            <path d={mdiEyeOutline} />
          </Icon>
        )}
        {breakpoint?.details?.title || children || 'Allow all'}
      </Badge>
    </Tooltip>
  )
}

export default VersionBreakpointBadge
