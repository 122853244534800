import { HStack } from '@chakra-ui/react'
import type { Style } from '@sitecore-feaas/sdk'
import { useContext } from 'react'
import { VersionContext } from '../../../contexts/VersionContext.js'
import VersionBreakpointBadge from './VersionBreakpointBadge.js'

export function VersionBreakpointsList() {
  const [{ breakpoint, version }, setContext] = useContext(VersionContext)
  return (
    <HStack
      className='ui size-breakpoints'
      alignContent='center'
      flexBasis={'auto'}
      justifyContent={'flex-end'}
      minWidth={0}
      spacing={0}
    >
      <HStack className='status' alignContent='center' flexBasis={'auto'} flexGrow={1} justifyContent={'flex-end'}>
        {version.getBreakpoints().map((b: Style.Rule<'breakpoint'>) => (
          <VersionBreakpointBadge
            key={b.details.id}
            isSelected={breakpoint?.details?.id === b.details.id}
            breakpoint={b}
            size='sm'
            onClick={() => setContext({ breakpoint: b })}
          />
        ))}
      </HStack>
    </HStack>
  )
}
