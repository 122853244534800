import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { mdiArrowUpLeft, mdiContentDuplicate, mdiFileTree, mdiSync } from '@mdi/js'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { titleize } from 'inflection'
import { Fragment, useCallback, useEffect, useRef } from 'react'
import { Style } from '@sitecore-feaas/sdk'
import { getContextIcon } from '../../../utils/element.js'
import { EditorChromeProps, ImplicitBorders } from './ChromeContext.js'
import { useLibrary } from '../../../exports.js'

export function ContextName({ context }: { context: CK.ModelElement }) {
  const rules = useLibrary('stylesheets.first.rules')
  if (!context) return
  const name = Style.Context.getContextLabel(context)
  const definition = Style.Set.getContextDefinition(rules, context, false)
  const custom = Style.Context.getContextCustomName(context)
  if (!definition.isCustom || !custom || !custom.startsWith(definition.className)) return <>{definition.label}</>
  return (
    <>
      {titleize(custom)}
      <Text ml={1} as='span' color='gray.400' fontWeight={'normal'}>
        {name}
      </Text>
    </>
  )
}

export default function ChromeContextParent(props: EditorChromeProps) {
  const { onPopoverOpen, onPopoverClose, onCommand, context, editor, onHighlight } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  const symbol = context ? editor.getElementSymbol(context) : null

  const menuItems = [
    ((context?.getAncestors().reverse() || []) as CK.ModelElement[]).map((a, index) => {
      if (a.is('rootElement')) return
      return (
        <Fragment key={index}>
          <MenuItem
            onMouseEnter={() => onHighlight(a)}
            onMouseLeave={() => onHighlight(null)}
            aria-label='Select parent'
            onClick={() => {
              onCommand('setContext', a)
              onClose()
            }}
            icon={
              <Icon boxSize='icon-md' mr={-1}>
                <path d={getContextIcon(a)} />
              </Icon>
            }
          >
            <ContextName context={a} />
            {a.getAttribute?.('data-path-scope') && (
              <Icon boxSize='icon-md' ml={2}>
                <path d={mdiSync} />
              </Icon>
            )}
          </MenuItem>
        </Fragment>
      )
    })
  ]
    .flat()
    .filter(Boolean)

  const closeRef = useRef<ReturnType<typeof setTimeout>>()
  const openRef = useRef<ReturnType<typeof setTimeout>>()

  const scheduleClose = useCallback(() => {
    clearTimeout(openRef.current)
    clearTimeout(closeRef.current)
    closeRef.current = setTimeout(onClose, 200)
  }, [onClose])
  const scheduleOpen = useCallback(() => {
    clearTimeout(openRef.current)
    clearTimeout(closeRef.current)
    openRef.current = setTimeout(onOpen, 300)
  }, [onOpen])

  const open = useCallback(() => {
    clearTimeout(closeRef.current)
    onOpen()
  }, [onOpen])

  const isRoot = context?.is('rootElement')
  useEffect(() => {
    clearTimeout(closeRef.current)
    onClose()
  }, [context])

  return (
    <>
      <Menu
        placement='bottom-start'
        offset={[-6, 10]}
        onOpen={() => {
          onPopoverOpen('parent')
        }}
        isLazy={true}
        onClose={() => {
          onPopoverClose('parent')
        }}
      >
        <Tooltip label='Select parent' placement='top'>
          <IconButton
            onMouseMove={scheduleOpen}
            onMouseLeave={scheduleClose}
            onClick={open}
            as={MenuButton}
            size='sm'
            css={ImplicitBorders}
            aria-label='Show parents'
            isDisabled={menuItems.length == 0}
            icon={
              <Icon boxSize='icon-lg'>
                <path d={mdiFileTree} />
              </Icon>
            }
          ></IconButton>
        </Tooltip>
        {menuItems.length > 0 && (
          <MenuList
            zIndex={5}
            style={{ width: 'auto', minWidth: 'auto' }}
            onMouseEnter={(isOpen && open) || null}
            onMouseLeave={(isOpen && scheduleClose) || null}
            css={css`
              pointer-events: ${isOpen ? 'all' : 'none'};
              &:before {
                position: absolute;
                margin-top: -20px;
                height: 10px;
                width: 100%;
                z-index: 1;
                content: '';
                display: block;
              }
            `}
          >
            {menuItems}
          </MenuList>
        )}
      </Menu>
      {symbol && (
        <Tooltip label={`Select original ${Style.Context.getContextLabel(symbol)}`}>
          <IconButton
            onClick={() => {
              onCommand('setContext', symbol)
            }}
            aria-label='Selected element'
            variant={'minimal'}
            icon={
              <Icon boxSize='icon-lg'>
                <path d={mdiContentDuplicate} />
              </Icon>
            }
            css={ImplicitBorders}
          />
        </Tooltip>
      )}
    </>
  )
}
