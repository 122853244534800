import {
  ButtonGroup,
  Divider,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { mdiFormatBold, mdiFormatClear, mdiFormatItalic, mdiLinkVariant, mdiLinkVariantOff } from '@mdi/js'
import { useEffect, useReducer } from 'react'
import { EditorChromeProps, ImplicitBorders } from './ChromeContext.js'
import { DialogLink } from './ChromeRangeLink.js'

export default function ChromeRangeToolbar(props: EditorChromeProps & { children: any }) {
  const { onPopoverOpen, onPopoverClose, onPopoverRegister, popover, editor, children, onCommand } = props
  const [, forceRender] = useReducer((r) => r + 1, 0)

  useEffect(() => {
    editor.model.document.selection.on('change:attribute', forceRender)
    //    editor.model.document.selection.on('change', forceRender)
    return () => {
      editor.model.document.selection.off('change:attribute', forceRender)
      //    editor.model.document.selection.off('change', forceRender)
    }
  }, [])

  const getCommandButtonProps = (commandName: string, attribute: string = commandName, preventExecution = false) => ({
    isActive: editor.model.document.selection.getAttribute(attribute) != null,
    isDisabled: !editor.commands.get(commandName).isEnabled,
    onClick: preventExecution
      ? undefined
      : () => {
          onCommand(commandName)
        }
  })

  const formatProps = getCommandButtonProps('removeFormat')
  const linkProps = getCommandButtonProps('link', 'linkHref', true)
  const unlinkProps = getCommandButtonProps('unlink')
  const boldProps = getCommandButtonProps('bold')
  const italicProps = getCommandButtonProps('italic')
  if (
    formatProps.isDisabled &&
    linkProps.isDisabled &&
    unlinkProps.isDisabled &&
    boldProps.isDisabled &&
    italicProps.isDisabled
  )
    return
  return (
    <Popover
      placement='bottom-start'
      autoFocus={false}
      isOpen={popover == 'range'}
      onOpen={() => onPopoverOpen('range')}
      isLazy={true}
    >
      <PopoverArrow />
      {/*@ts-ignore*/}
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent {...{ style: { width: 'auto', border: 0, background: 'none', pointerEvents: 'all' } }}>
        <ButtonGroup
          spacing={'4px'}
          css={css`
            padding: 4px 3px 4px 4px;
            background: #fff;
            position: absolute;
            align-items: center;
            display: inline-flex;
            border: 1px solid #94949d;
            border-radius: 30px;
            height: 32px;

            > hr {
              height: 24px;
              border-color: #e4e4e7;
            }
          `}
          color='rgba(0,0,0,0.68)'
        >
          <IconButton
            aria-label='Bold text'
            icon={
              <Icon boxSize='icon-lg'>
                <path d={mdiFormatBold} />
              </Icon>
            }
            variant='minimal'
            css={ImplicitBorders}
            size='sm'
            {...boldProps}
          />
          <IconButton
            aria-label='Italic text'
            icon={
              <Icon boxSize='icon-lg'>
                <path d={mdiFormatItalic} />
              </Icon>
            }
            variant='minimal'
            css={ImplicitBorders}
            size='sm'
            {...italicProps}
          />
          {!linkProps.isDisabled && (
            <Popover isLazy={true} placement='bottom-start' offset={[-58, -28]}>
              {({ onClose }) => (
                <>
                  {onPopoverRegister('block', onClose)}
                  <PopoverTrigger>
                    <IconButton
                      aria-label='Link text'
                      icon={
                        <Icon boxSize='icon-lg'>
                          <path d={mdiLinkVariant} />
                        </Icon>
                      }
                      variant='minimal'
                      css={ImplicitBorders}
                      size='sm'
                      {...linkProps}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <DialogLink onNavigate={(context) => editor.execute('setContext', context)} />
                  </PopoverContent>
                </>
              )}
            </Popover>
          )}
          {(!formatProps.isDisabled || !unlinkProps.isDisabled) && <Divider orientation='vertical' />}
          {!unlinkProps.isDisabled && (
            <IconButton
              aria-label='Remove format'
              icon={
                <Icon boxSize='icon-lg'>
                  <path d={mdiLinkVariantOff} />
                </Icon>
              }
              variant='minimal'
              css={ImplicitBorders}
              size='sm'
              {...unlinkProps}
            />
          )}
          {!formatProps.isDisabled && (
            <IconButton
              aria-label='Remove format'
              icon={
                <Icon boxSize='icon-lg'>
                  <path d={mdiFormatClear} />
                </Icon>
              }
              variant='minimal'
              css={ImplicitBorders}
              size='sm'
              {...formatProps}
            />
          )}
        </ButtonGroup>
      </PopoverContent>
    </Popover>
  )
}
