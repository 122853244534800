import { Icon, IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'
import { mdiPaletteAdvanced } from '@mdi/js'
import { forwardRef, useContext } from 'react'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { VersionContext } from '../../../contexts/VersionContext.js'

export const VersionThemeButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const [{ activeVersionId, sidebarMode, setActiveVersionId }, setEditorContext] = useContext(EditorContext)
  const [
    {
      version: { id }
    }
  ] = useContext(VersionContext)

  return (
    <IconButton
      ref={ref}
      {...props}
      size='xs'
      variant={'chrome'}
      colorScheme='primary'
      isActive={sidebarMode == 'themes' && activeVersionId == id}
      onClick={() => {
        setActiveVersionId(id)
        setEditorContext({
          sidebarMode: 'themes',
          sidebarDialog: null
        })
      }}
      aria-label='Change theme'
      icon={
        <Icon fontSize='icon-lg'>
          <path d={mdiPaletteAdvanced} />
        </Icon>
      }
    />
  )
})

export default VersionThemeButton
