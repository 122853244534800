import { Box, BoxProps, useOutsideClick, useToast } from '@chakra-ui/react'
import { useContext, useRef } from 'react'
import { default as ReactShadowRoot } from 'react-shadow-root'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { VersionContext } from '../../../contexts/VersionContext.js'

export default function VersionPreview(props: BoxProps) {
  const [{ isFocused, activeVersionId, setActiveVersionId }, setEditorContext] = useContext(EditorContext)
  const [{ breakpoint, snapshotStatus, version }, setVersionContext] = useContext(VersionContext)
  const { id } = version
  const refPreview = useRef<HTMLDivElement>(null)
  const toast = useToast()
  const maxWidth = isFinite(breakpoint?.props.maxWidth) ? breakpoint?.props.maxWidth : 2000

  useOutsideClick({
    ref: refPreview,
    handler: (e: MouseEvent) => {
      if (!(e.target instanceof HTMLElement)) return
      if (!e.target.closest(`[data-version-id="${id}"] .ui, .chrome .ui`)) {
        setEditorContext({
          isFocused: false
        })
        setVersionContext({
          snapshotStatus: null
        })
      }
    }
  })

  return (
    <Box
      className='preview ui'
      position='relative'
      borderRadius='4px'
      overflow={'hidden'}
      display='flex'
      background='repeating-linear-gradient(
                45deg,
                rgba(0, 0, 0, 0.05),
                rgba(0, 0, 0, 0.05) 20px,
                rgba(255, 255, 255, 0.05) 20px,
                rgba(255, 255, 255, 0.05) 40px
              )'
      {...props}
    >
      <Box
        background='white'
        mx='auto'
        width='100%'
        minW={breakpoint?.props.minWidth}
        maxW={maxWidth}
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (isFocused && activeVersionId == id) {
            toast({
              isClosable: true,
              duration: 6000,
              status: 'info',
              title: 'You are viewing a snapshot',
              description: 'Snapshot can not be edited. Click Revert to use snapshot as draft and edit it.'
            })
          } else {
            setActiveVersionId(id)
          }
        }}
      >
        {/*@ts-ignore TS 4.9 FIXME*/}
        <ReactShadowRoot>
          <style>{version.library.stylesheet.css}</style>
          <Box
            ref={refPreview}
            tabIndex={0}
            className={`-feaas ${version.classList.join(' ')} ${
              breakpoint ? `-emulate--${breakpoint.details.slug} ` : ''
            } -feaas--preview`}
            dangerouslySetInnerHTML={{ __html: version.getCurrent(snapshotStatus)?.view }}
          />
        </ReactShadowRoot>
      </Box>
    </Box>
  )
}
