import type * as CK from '@sitecore-feaas/ckeditor5'
import { HStack, Icon, IconButton, Tooltip } from '@chakra-ui/react'
import { getContextIcon } from '../../../utils/element.js'
import { ContextName } from './ChromeContextParent.js'
import { mdiChevronRight, mdiSync } from '@mdi/js'
import { ImplicitBorders, onEditorCommand, onEditorHighlight } from './ChromeContext.js'
import { Style } from '@sitecore-feaas/sdk'

const ChromeBreadcrumbsParents = ({
  context,
  onHighlight,
  onCommand
}: {
  context: CK.ModelElement
  onHighlight: onEditorHighlight
  onCommand: onEditorCommand
}) => {
  const parents = ((context?.getAncestors() || []) as CK.ModelElement[]).filter(
    (element) => !element.is('rootElement') && Boolean
  )

  return (
    <>
      <HStack
        spacing={0}
        divider={
          <Icon color='blackAlpha.400' border='0' fontSize='icon-xs'>
            <path d={mdiChevronRight} />
          </Icon>
        }
      >
        {parents.map((parent, index) => (
          <Tooltip key={index} label={Style.Context.getContextLabel(parent)} placement='top'>
            <IconButton
              onMouseEnter={() => onHighlight(parent)}
              onMouseLeave={() => onHighlight(null)}
              size='sm'
              css={ImplicitBorders}
              pr={'1'}
              pl={parent.getAttribute?.('data-path-scope') ? '1' : null}
              aria-label='Select parent'
              onClick={() => {
                onCommand('setContext', parent)
              }}
              icon={
                <>
                  <Icon boxSize='icon-md' mr={-1}>
                    <path d={getContextIcon(parent)} />
                  </Icon>
                  {parent.getAttribute?.('data-path-scope') && (
                    <Icon boxSize='icon-md' ml={2}>
                      <path d={mdiSync} />
                    </Icon>
                  )}
                </>
              }
            >
              <ContextName context={parent} />
            </IconButton>
          </Tooltip>
        ))}
      </HStack>
      {parents.length > 0 && (
        <Icon color='blackAlpha.400' border='0' fontSize='icon-xs'>
          <path d={mdiChevronRight} />
        </Icon>
      )}
    </>
  )
}

export default ChromeBreadcrumbsParents
