import { useEffect, useState } from 'react'

export function useEditorValue(editorObject: any, key: string) {
  const [value, setValue] = useState(editorObject ? editorObject[key] : null)

  useEffect(() => {
    if (!editorObject) return
    const onChange = (evt: any, name: string, newValue: any) => {
      //console.log('Editor value changed:', key, newValue)
      return setValue(newValue)
    }
    editorObject.on('change:' + key, onChange)
    return () => {
      editorObject.off('change:' + key, onChange)
    }
  }, [editorObject, key])

  return value
}
