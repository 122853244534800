import useResizeObserver from '@react-hook/resize-observer'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { Style } from '@sitecore-feaas/sdk'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import 'setimmediate'
import { EditorContext } from '../../../contexts/EditorContext.js'
import { useDocumentEffect } from '../../../hooks/useDocumentEffect.js'
import { documentPositionComparator, getActiveElement, measureElement, Measurement } from '../../../utils/dom.js'
import { getHTMLElementPlacements, Placement, PlacementWalkStrategy, Point } from '../../../utils/placement.js'
import { getRectStyle, offsetRect, Rect } from '../../../utils/rect.js'
import ChromeBlockToolbar from './ChromeBlockToolbar.js'
import { ChromeRefs, EditorChromeElement, EditorChromeInputs, EditorChromeProps, Inputs } from './ChromeContext.js'
import ChromeContextToolbar from './ChromeContextToolbar.js'
import ChromeOrchestratorGrid from './ChromeOrchestratorGrid.js'
import ChromeOrchestratorPlacement from './ChromeOrchestratorPlacement.js'
import ChromeRangeToolbar from './ChromeRangeToolbar.js'
import ChromeBreadcrumbs from './ChromeBreadcrumbs.js'
import { useSDK } from '../../../hooks/useData.js'
const emptyBox = { left: 0, top: 0, width: 0, height: 0 }

const emptyMeasurements: Inputs<Measurement> = {
  root: null,
  highlight: null,
  selection: null,
  focusable: null,
  context: null,
  section: null,
  block: null,
  placed: null
}

/**
 * Orchestrator manages appearance, rendering and positiong of UI toolbars and panels. The goal is to avoid jank and
 * flickering when inputs are changing. It does so by explicitly controlling render properties and measurements. The
 * nested UI elements are not allowed to re-render on their own, so they dont subscribe to context or model changes.
 * Instead, the lifecycle of the UI consists of Measurement and Rendering phase. UI elements are positioned and rendered
 * all within a single render tick, miniminzing amout of visual noise
 */
export default function ChromeOrchestrator({
  inputs,
  conditions,
  props,
  context
}: {
  inputs: EditorChromeInputs
  props: EditorChromeProps
  context: EditorContext
  conditions: Record<EditorChromeElement, boolean>
}) {
  const {
    isDataDisplayed,
    isDataRepeated,
    isArchivedDisplayed,
    isHiddenDisplayed,
    isChromeVisible,
    editor,
    root,
    onChromeMeasure,
    onChromeMeasureElement,
    onChromeBeforeMeasure,
    onChromePosition,
    blur
  } = context

  /** Snapshot of properties and elements that was used for last measurement */
  const { current: refs } = useRef<ChromeRefs>({
    inputs: { ...inputs },
    memoized: { ...inputs },
    placements: [],
    matchedPlacements: [],
    pointer: { left: 0, top: 0 },
    scroll: { left: 0, top: 0 },
    measurements: { ...emptyMeasurements },
    clonables: [],
    variables: [],
    spacers: [],
    embeds: []
  })

  const sdk = useSDK()
  const { memoized } = refs

  function getPlacementStrategy(
    context: CK.ModelElement,
    element: HTMLElement,
    parent: Placement
  ): PlacementWalkStrategy {
    // UL/OL dont exist inside CK Model, so we dive deeper
    if (!context && (element.tagName == 'UL' || element.tagName == 'OL')) {
      return 'descend'
    }
    if (context?.name == 'ui') return 'block'
    if (!context) return 'skip'

    return editor.model.schema.isBlock(context) ? 'text' : parent ? 'block' : 'ignore'
  }

  const [measurements, setMeasured] = useState<Inputs<Measurement>>(() => emptyMeasurements)
  const [positions, setPositions] = useState<Inputs<Rect>>(() => {
    const empties = {} as Inputs<Rect>
    for (var prop in emptyMeasurements) empties[prop as keyof typeof empties] = { ...emptyBox }
    return empties
  })
  const recomputing = useRef(null)

  const recompute = useCallback(
    (e?: Event | ResizeObserverEntry) => {
      // dont remeasure on scroll unless placing element
      //console.log('scroll')
      if (
        e instanceof Event &&
        (e?.target == document || (e?.target as HTMLElement)?.classList.contains('editor-content')) &&
        //!memoized.placed &&
        e.type != 'feaasRefresh'
      )
        return

      cancelAnimationFrame(recomputing.current)
      recomputing.current = requestAnimationFrame(() => {
        Object.assign(memoized, inputs)
        onChromeBeforeMeasure?.(refs)

        try {
          // throw on hot page reload
          // TODO: Context changed caused by click and not selection re-renders context
          var selectionRect =
            memoized.block &&
            editor.contextPlugin.getSelectionRect() &&
            onChromeMeasureElement(
              editor.utils.modelToDOMElement(memoized.block),
              editor.contextPlugin.getSelectionRect()
            )

          var selectedElement = editor.utils.modelToDOMElement(
            editor.model.document.selection.focus.parent as CK.ModelElement
          )
        } catch (e) {
          console.error(e)
        }

        sdk.log('measure', e instanceof ResizeObserverEntry ? 'resize' : e ? 'scroll' : 'change')

        const newMeasured = {
          root: measureElement(memoized.root, onChromeMeasureElement),
          highlight: measureElement(editor.utils.modelToDOMElement(memoized.highlight), onChromeMeasureElement),
          selection: selectionRect ? measureElement(selectedElement, onChromeMeasureElement, selectionRect) : null,
          focusable: measureElement(memoized.focusable, onChromeMeasureElement),
          section: measureElement(editor.utils.modelToDOMElement(memoized.section), onChromeMeasureElement),
          context: measureElement(editor.utils.modelToDOMElement(memoized.context), onChromeMeasureElement),
          block: measureElement(editor.utils.modelToDOMElement(memoized.block), onChromeMeasureElement),
          placed: measureElement(editor.utils.modelToDOMElement(memoized.placed), onChromeMeasureElement)
        }
        // scroll at the time of measurement
        refs.scroll.top = memoized.root.offsetParent.scrollTop
        refs.scroll.left = memoized.root.offsetParent.scrollLeft

        Object.assign(refs.measurements, newMeasured)
        const repeatedElements = Array.from(
          memoized.focusable?.querySelectorAll('[data-path-scope], [data-symbol-ref]') || []
        ).filter(
          (c) =>
            !c.getAttribute('data-path-scope') ||
            c.getAttribute('data-path-scope') == c.previousElementSibling?.getAttribute('data-path-scope')
        )
        refs.clonables = repeatedElements.map((c) => measureElement(c as HTMLElement, onChromeMeasureElement))
        refs.embeds = Array.from(
          memoized.focusable?.querySelectorAll(
            '.-component:not([data-embed-title]), .-embed:not([data-embed-title])'
          ) || []
        ).map((c) => measureElement(c as HTMLElement, onChromeMeasureElement))
        refs.variables = Array.from(memoized.focusable?.querySelectorAll('var') || [])
          .filter((v: HTMLElement) => !repeatedElements.some((r) => r.contains(v)))
          .map((c) => measureElement(c as HTMLElement, onChromeMeasureElement))
          .filter((c) => c)
        refs.spacers = Array.from(memoized.focusable?.querySelectorAll('.-spacer') || [])
          //.filter((v: HTMLElement) => !repeatedElements.some((r) => r.contains(v)))
          .map((c) => measureElement(c as HTMLElement, onChromeMeasureElement))
          .filter((c) => c)
        const editables = editor.model.document
          .getRootNames()
          .map((rootName) => {
            return editor.editing.view.getDomRoot(rootName) as any as HTMLElement
          })
          .sort(documentPositionComparator)
        refs.placements = !props.placed
          ? []
          : getHTMLElementPlacements(
              root,
              (element) => {
                if (element == root) {
                  return {
                    name: 'ui',
                    getAttribute: (name: string) => null
                  } as CK.ModelElement
                }
                return editor.DOMElementToModel(element, true, false)
              },
              getPlacementStrategy,
              undefined,
              editables,
              64,
              onChromeMeasureElement
            )
        onChromeMeasure?.(newMeasured, refs)
        setMeasured(newMeasured)
        props.grids
          .map((grid) => {
            const measurement = measureElement(
              editor.utils.modelToDOMElement(grid.context as CK.ModelElement),
              onChromeMeasureElement,
              undefined,
              false
            )
            if (!measurement) return
            grid.compute(measurement.rect, measurement.styles, {
              left: measurement.rect.left - measurement.parentRect.left,
              top: measurement.rect.top - measurement.parentRect.top
            })
            return grid
          })
          .filter(Boolean)
      })
    },
    [editor]
  )

  useLayoutEffect(recompute, [
    isHiddenDisplayed,
    isDataDisplayed,
    isDataRepeated,
    isArchivedDisplayed,
    props.customStyles,
    ...Object.values(inputs)
  ])

  // @ts-ignore  TS 4.9+ issue Fixme: expression is not callable
  useResizeObserver(document.body, recompute)
  // @ts-ignore  TS 4.9+ issue Fixme: expression is not callable
  useResizeObserver(editor.utils.modelToDOMElement(memoized.block), recompute)
  // @ts-ignore  TS 4.9+ issue Fixme: expression is not callable
  useResizeObserver(editor.utils.modelToDOMElement(memoized.context), recompute)
  // @ts-ignore  TS 4.9+ issue Fixme: expression is not callable
  useResizeObserver(memoized.focusable, recompute)

  const isInteracting = props.grids.some((g) => g?.interacting?.anchor)
  const isVisible = isChromeVisible && measurements.focusable?.rect.width > 0
  const isGridVisible = memoized.context?.is('element', 'section')
  conditions.context = !isInteracting && isVisible && !memoized.placed
  conditions.outline = isVisible && !memoized.placed && !memoized.context?.is('element', 'var')
  conditions.highlight = isVisible && !!memoized.highlight
  conditions.section = isVisible && !!memoized.section

  conditions.range =
    !editor.model.document.selection.isCollapsed &&
    !memoized.placed &&
    isVisible &&
    measurements.selection?.rect.width > 5 &&
    memoized.block &&
    isVisible &&
    memoized.selectionIndex != null &&
    !memoized.object
  conditions.block = isVisible && !isInteracting && !memoized.placed && !!measurements.block?.rect
  conditions.placed =
    isVisible && memoized.placed && (!memoized.placed?.parent || !memoized.placed.parent?.is('element', 'section'))
  conditions.placement = isVisible && !!props.placement
  conditions.place = !isInteracting && isVisible && !!memoized.placed
  const orchestratorProps = {
    conditions,
    measurements,
    positions,
    refs,
    context,
    props,
    isVisible
  }

  useEffect(props.onPopoverClose, [
    isVisible,
    conditions.placed,
    conditions.context,
    conditions.range,
    conditions.placement
  ])
  useEffect(() => {
    const selectionOrBlock = measurements.selection?.rect
    if (selectionOrBlock && memoized.block) {
      var blockHeight = 30
      var blockWidth = 30
      var blockBox = {
        top: selectionOrBlock.top + selectionOrBlock.height / 2 - blockHeight / 2,
        left: measurements.context?.rect.left - blockWidth,
        width: blockWidth,
        height: blockHeight
      }
    }
    //console.log('set positions', measurements, positions)
    setPositions((previous) => ({
      ...previous,
      root: measurements.root?.rect || previous.root,
      highlight: measurements.highlight?.rect || previous.highlight,
      selection: conditions.range ? measurements.selection.rect : previous.selection,
      focusable: measurements.focusable?.rect || previous.focusable,
      context: measurements.context?.rect || previous.context,
      block: (conditions.block && blockBox) || previous.block,
      placed: measurements.placed?.rect || previous.placed,
      section: measurements.section?.rect || previous.section
    }))
  }, [
    conditions.block,
    conditions.range,
    memoized.context,
    ...Object.values(measurements)
      .map((m) => Object.values(m?.rect || emptyBox))
      .flat()
      .map((c) => Math.round(c))
  ])

  useDocumentEffect(
    memoized.focusable,
    (doc) => {
      const onPointerMove = (e: PointerEvent) => {
        if (!memoized.root.offsetParent) return
        Object.assign(
          refs.pointer,
          onChromeMeasureElement(e.target as HTMLElement, {
            left: Math.floor(e.clientX - refs.scroll.left + memoized.root.offsetParent.scrollLeft),
            top: Math.floor(e.clientY - refs.scroll.top + memoized.root.offsetParent.scrollTop),
            width: 0,
            height: 0
          })
        )
      }
      doc.addEventListener('pointermove', onPointerMove, { capture: true })
      doc.addEventListener('drag', onPointerMove, { capture: true })
      return () => {
        doc.removeEventListener('pointermove', onPointerMove, { capture: true })
        doc.removeEventListener('drag', onPointerMove, { capture: true })
      }
    },
    []
  )
  useDocumentEffect(
    memoized.focusable,
    (doc) => {
      doc.addEventListener('scroll', recompute, { capture: true })
      doc.addEventListener('feaasRefresh', recompute, { capture: true })
      return () => {
        doc.removeEventListener('scroll', recompute, { capture: true })
        doc.removeEventListener('feaasRefresh', recompute, { capture: true })
      }
    },
    []
  )

  useDocumentEffect(
    memoized.focusable,
    (doc) => {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key == 'Escape') {
          const activeElement = getActiveElement(doc)
          if (activeElement.getAttribute('contenteditable') != null) {
            blur()
            e.preventDefault()
            e.stopPropagation()
          }
        }
      }
      doc.addEventListener('keydown', onKeyDown, { capture: true })
      return () => {
        doc.removeEventListener('keydown', onKeyDown, { capture: true })
      }
    },
    []
  )
  const rangeToolbar = useMemo(() => {
    sdk.log('Render Range')
    return (
      <div
        style={{
          position: 'absolute',
          zIndex: 5
        }}
      >
        <ChromeRangeToolbar {...props}>
          <div
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              zIndex: 5,
              ...getRectStyle(positions.selection)
            }}
          ></div>
        </ChromeRangeToolbar>
      </div>
    )
  }, [props.popover == 'range', ...Object.values(positions.selection || emptyBox)])

  const contextToolbar = useMemo(() => {
    sdk.log('Render Context: ' + memoized.context?.name)
    return <ChromeContextToolbar {...props} />
  }, [memoized.context, memoized.context?.name, memoized.placed, props.grids])

  const blockToolbar = useMemo(() => {
    sdk.log('Render Block: ' + memoized.block?.name)
    return <ChromeBlockToolbar {...props} />
  }, [props.popover == 'block', memoized.block?.getAttribute('class'), memoized.block?.name])

  const positionableToolbars = useMemo(() => {
    onChromePosition?.(positions)
    return (
      <>
        <div
          style={{
            border: '2px solid rgba(131,131,190,0.5)',
            position: 'absolute',
            background: `repeating-linear-gradient(
              45deg,
              rgba(131,131,190,0.3),
              rgba(131,131,190,0.3) 20px,
              rgba(131,131,190,0.2) 20px,
              rgba(131,131,190,0.2) 40px
            ) fixed`,
            zIndex: 3,
            pointerEvents: 'none',
            opacity: conditions.highlight ? 1 : 0,
            transition: 'opacity 0.2s',
            borderRadius: measurements.highlight?.styles?.borderRadius,
            ...getRectStyle(positions.highlight, true)
          }}
        ></div>
        {rangeToolbar}
        <ChromeOrchestratorPlacement {...orchestratorProps} />
        <div
          style={{
            border: '1px solid var(--chakra-colors-primary-300)',
            position: 'absolute',
            zIndex: 3,
            pointerEvents: 'none',
            opacity: conditions.outline ? 1 : 0,
            boxShadow: '0 0 1px var(--chakra-colors-primary-50)',
            transition: 'opacity 0.2s',
            borderRadius:
              measurements.context?.styles?.borderRadius == '0px' ? '4px' : measurements.context?.styles?.borderRadius,
            ...getRectStyle(offsetRect(positions.context, -1))
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            zIndex: 6,
            opacity: conditions.context ? 1 : 0,
            transition: /*conditions.place ? 'opacity 0.2s 0.1s' : */ 'opacity 0.2s',
            pointerEvents: conditions.context ? 'all' : 'none',
            ...getRectStyle({
              ...positions.context,
              left: Math.min(positions.context?.left, positions.root?.width - 306),
              top: Math.max(positions.context?.top, positions.focusable?.top + 24)
            }),
            width: '',
            height: ''
          }}
        >
          {contextToolbar}
        </div>
        <div
          style={{
            position: 'absolute',
            zIndex: 5,
            marginLeft: positions.block?.left > 10 ? '-5px' : '1px',
            ...getRectStyle(positions.block),
            height: '30px',
            width: '30px',
            pointerEvents: conditions.block ? 'all' : 'none',
            transition: 'opacity 0.2s',
            opacity: conditions.block ? 1 : 0
          }}
        >
          {blockToolbar}
        </div>
        {refs.clonables.map((clonable, i) => (
          <div
            key={i}
            style={{
              position: 'absolute',
              zIndex: 3,
              opacity: !isGridVisible && memoized.focusable && isVisible ? 0.8 : 0,
              transition: 'opacity 0.1s ',
              pointerEvents: 'none',
              background: `repeating-linear-gradient(
              45deg,
              rgba(0,0,0,0.08),
              rgba(0,0,0,0.08) 20px,
              rgba(0,0,0,0.04) 20px,
              rgba(0,0,0,0.04) 40px
            ) fixed`,
              borderRadius: clonable?.styles?.borderRadius == '0px' ? '4px' : clonable?.styles?.borderRadius,

              backdropFilter: 'saturate(30%) grayscale(50%)',
              ...getRectStyle(clonable.rect, true)
            }}
          ></div>
        ))}
        {refs.embeds.map((embed, i) => (
          <div
            key={i}
            style={{
              position: 'absolute',
              zIndex: 4,
              opacity: !isGridVisible && memoized.focusable && isVisible ? 0.3 : 0,
              transition: 'opacity 0.1s ',
              pointerEvents: 'none',
              background: `url("https://feaasstatic.blob.core.windows.net/assets/sample/component.svg")`,
              backgroundSize: '80px 80px',
              backgroundPosition: 'center',
              borderRadius: embed?.styles?.borderRadius == '0px' ? '4px' : embed?.styles?.borderRadius,

              backdropFilter: 'saturate(30%) grayscale(50%)',
              ...getRectStyle(embed.rect, true)
            }}
          ></div>
        ))}
        {refs.variables.map((variable, i) => {
          const variableModel = editor.DOMElementToModel(variable.element)
          const isSelected =
            editor.current.context == variableModel ||
            (!editor.model.document.selection.isCollapsed &&
              editor.model.document.selection.containsEntireContent(variableModel)) ||
            variableModel == editor.model.document.selection.focus.parent
          return variable.rects.map((rect, j) => (
            <div
              key={j + '-' + i}
              style={{
                position: 'absolute',
                zIndex: 3,
                opacity: isSelected ? 0.4 : !isGridVisible && isVisible ? 0.8 : 0,
                backgroundColor: isSelected ? 'var(--chakra-colors-primary-500)' : '',
                transition: 'opacity 0.1s, background-color 0.2s',
                pointerEvents: 'none',
                boxSizing: 'content-box',
                marginTop: '-2px',
                marginLeft: '-4px',
                padding: '2px 4px',
                boxShadow: '2px 2px 0  rgba(0,0,0,0.2)',
                backdropFilter: isVisible ? 'brightness(120%) invert(10%)' : '',
                ...getRectStyle(rect)
              }}
            ></div>
          ))
        })}
        {refs.spacers.map((spacer, i) => (
          <div
            key={i}
            style={{
              position: 'absolute',
              zIndex: 4,
              opacity: !isGridVisible && memoized.focusable && isVisible ? 0.3 : 0,
              transition: 'opacity 0.1s ',
              pointerEvents: 'none',
              background:
                spacer.rect.height < 20 || true
                  ? 'rgba(0,0,0,0.35)'
                  : `url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none"><path d="M7.06953 10.9305L4.93047 10.9305L4.93047 4.10367L2.20733 6.8268L0.690266 5.30973L6 0L11.3097 5.30973L9.79267 6.8268L7.06953 4.10367L7.06953 10.9305Z" fill="black" fill-opacity="0.25"/></svg>') no-repeat top,
              url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none"><path d="M0.0695325 7.06953V4.93047L6.89633 4.93047L4.1732 2.20733L5.69027 0.690265L11 6L5.69027 11.3097L4.1732 9.79267L6.89633 7.06953L0.0695325 7.06953Z" fill="black" fill-opacity="0.25"/></svg>') no-repeat right,
              url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none"><path d="M10.9305 4.93047V7.06953L4.10367 7.06953L6.8268 9.79267L5.30973 11.3097L0 6L5.30973 0.690265L6.8268 2.20733L4.10367 4.93047L10.9305 4.93047Z" fill="black" fill-opacity="0.25"/></svg>') no-repeat left,
              url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none"><path d="M4.93047 0.0695335L7.06953 0.0695333L7.06953 6.89633L9.79267 4.1732L11.3097 5.69027L6 11L0.690265 5.69027L2.20733 4.1732L4.93047 6.89633L4.93047 0.0695335Z" fill="black" fill-opacity="0.25"/></svg>') no-repeat bottom,
              url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="2" height="2" viewBox="0 0 2 2" fill="none"><path d="M1.99991 2L1.99991 5.97985e-05H-5.97985e-05L-3.42285e-08 2H1.99991Z" fill="black" fill-opacity="0.25"/></svg>') repeat-x center content-box,
              url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="2" height="2" viewBox="0 0 2 2" fill="none"><path d="M1.99991 2L1.99991 5.97985e-05H-5.97985e-05L-3.42285e-08 2H1.99991Z" fill="black" fill-opacity="0.25"/></svg>') repeat-y center content-box `,
              padding: spacer.rect.height < 20 ? 0 : 11,
              border: '1px dashed rgba(0,0,0,0.55)',
              borderRadius: 3,
              ...getRectStyle(offsetRect(spacer.rect, 0), true)
            }}
          ></div>
        ))}
      </>
    )
  }, [isVisible, conditions.context, props.popover, positions, memoized.placed, props.placement, props.grids])

  return (
    <div
      className={`ui overlays ${isVisible ? 'ui-visible' : ''}`}
      style={{
        zIndex: 3,
        position: 'absolute',
        marginLeft: -positions.root.left + 'px',
        marginTop: -positions.root.top + 'px',
        pointerEvents: isVisible ? 'all' : 'none'
      }}
    >
      {positionableToolbars}
      <div
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          zIndex: '3',
          display: 'flex',
          alignItems: 'flex-end',
          transition: 'opacity 0.2s',
          opacity: isVisible ? 1 : 0,
          ...getRectStyle(positions.focusable, true)
        }}
      >
        <ChromeOrchestratorGrid {...orchestratorProps} />
        <ChromeBreadcrumbs context={memoized.context} onCommand={props.onCommand} onHighlight={props.onHighlight} />
      </div>
    </div>
  )
}
