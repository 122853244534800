import { Icon } from '@chakra-ui/react'
import { mdiTriangle } from '@mdi/js'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { useCallback, useLayoutEffect, useMemo } from 'react'
import { useDocumentEffect } from '../../../hooks/useDocumentEffect.js'
import { getActiveElement } from '../../../utils/dom.js'
import { getRectStyle, offsetRect } from '../../../utils/rect.js'
import { ChromeOrchestratorProps, findEqualPlacement, getPlacementEdge, matchPlacements } from './ChromeContext.js'
import ChromePlacementToolbar from './ChromePlacementToolbar.js'
import ChromePlaceToolbar from './ChromePlaceToolbar.js'
import { useSDK } from '../../../hooks/useData.js'
import { isDeepEquals } from '@sitecore-feaas/sdk'

export default function ChromeOrchestratorPlacement({
  refs,
  props,
  context,
  conditions,
  positions,
  measurements,
  isVisible
}: ChromeOrchestratorProps) {
  const { memoized } = refs
  const { editor, onChromeMeasureElement } = context
  const sdk = useSDK()

  useDocumentEffect(
    memoized.focusable,
    (doc) => {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key == 'Escape') {
          const activeElement = getActiveElement(doc)
          if (activeElement.getAttribute('contenteditable') != null) {
            blur()
            e.preventDefault()
            e.stopPropagation()
          } else if (!activeElement.closest?.('[class*="menu-list"]') && conditions.placed) {
            props.onPlacementStart(null)
            e.preventDefault()
            e.stopPropagation()
          }
        }
      }
      doc.addEventListener('keydown', onKeyDown, { capture: true })
      return () => {
        doc.removeEventListener('keydown', onKeyDown, { capture: true })
      }
    },
    []
  )

  useDocumentEffect(memoized.focusable, (doc) => {
    const onDragOver = (e: DragEvent) => {
      if (props.placed && props.placement) {
        e.dataTransfer.dropEffect = 'move'
        e.preventDefault()
      }
    }
    doc.addEventListener('dragover', onDragOver)
    return () => {
      doc.removeEventListener('dragover', onDragOver)
    }
  })

  const matchAllPlacements = useCallback(() => {
    // dont update placements while popover is open
    const placingVersion = memoized.placed?.is('rootElement')
    const matchDistance = placingVersion ? 100 : 10
    refs.matchedPlacements = matchPlacements(refs.placements, refs.pointer, matchDistance).filter((placement) => {
      if (!memoized.placed || !placement.parent) return
      const parentContext = placement.parent.context as CK.ModelElement

      // dont select pseudo page parent when placing version
      if (memoized.placed.is('rootElement')) {
        return parentContext.name == 'ui'
      }

      // avoid placing element inside of itself
      for (var p = placement.parent?.context; p; p = p.parent as CK.ModelElement) {
        if (p == memoized.placed) {
          return
        }
      }
      // dont allow dropping into sections to avoid conflicting interactions with grid
      if (parentContext?.name == 'section') return

      // check if schema allows placement
      return editor.model.schema.checkChild(parentContext.name == 'ui' ? 'ui' : parentContext, memoized.placed)
    })
    // only pick the first choice for version placements
    if (placingVersion) refs.matchedPlacements.splice(1)

    // when no menu is open, cursor updates placement target
    if (props.popover !== 'placement') {
      var placement = refs.matchedPlacements[0] || null
      if (!isDeepEquals(props.placement, placement)) {
        props.onPlacementOver(placement)
      }
    } else if (props.placement) {
      props.onPlacementOver(findEqualPlacement(props.placement, refs.placements))
    }
  }, [])

  useLayoutEffect(() => {
    conditions.placed && matchAllPlacements()
  }, [positions.root.top, props.placement])

  useDocumentEffect(
    memoized.focusable,
    (doc) => {
      var timeout: ReturnType<typeof setTimeout>
      const onPlacementMove = ({ clientX: left, clientY: top, target }: MouseEvent) => {
        if (!conditions.placed) return
        clearTimeout(timeout)
        timeout = setTimeout(matchAllPlacements, 16)
      }
      doc.addEventListener('drag', onPlacementMove)
      doc.addEventListener('pointermove', onPlacementMove)
      return () => {
        doc.removeEventListener('drag', onPlacementMove)
        doc.removeEventListener('pointermove', onPlacementMove)
      }
    },
    []
  )

  const placementToolbar = useMemo(() => {
    sdk.log('Render placement toolbar: ', (props.placement?.context as CK.ModelElement)?.name)
    if (conditions.placed)
      return <ChromePlacementToolbar {...props} onChromeMeasureElement={onChromeMeasureElement} refs={refs} />
  }, [props.placement, memoized.placed])

  const placeToolbar = useMemo(() => {
    sdk.log('Render Place: ', props.placement)
    return <ChromePlaceToolbar {...props} refs={refs} />
  }, [memoized.placed, props.placement, memoized.context])

  return (
    <>
      <div
        style={{
          border: '1px dashed var(--chakra-colors-primary-500)',
          background: 'var(--chakra-colors-primary-500)',
          position: 'absolute',
          zIndex: 3,
          pointerEvents: 'none',
          opacity: conditions.placed && memoized.placed?.parent ? 0.3 : 0,
          transition: 'opacity 0.2s',
          borderRadius:
            measurements.placed?.styles?.borderRadius == '0px' ? '4px' : measurements.placed?.styles?.borderRadius,
          ...getRectStyle(offsetRect(positions.placed, -1))
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          zIndex: 7,
          opacity: conditions.place ? 1 : 0,
          transition: conditions.context ? 'opacity 0.2s 0.1s' : 'opacity 0.2s',
          pointerEvents: conditions.place ? 'all' : 'none',
          ...getRectStyle(positions.context),
          width: '',
          height: ''
        }}
      >
        {placeToolbar}
      </div>
      <div
        className='chrome-indicator-placement-parent'
        style={{
          opacity: !memoized.placed?.is('rootElement') && conditions.placement && conditions.placed ? 1 : 0,
          transition: 'opacity 0.2s',
          ...getRectStyle(props.placement?.parent.area),
          position: 'absolute',
          border: '1px solid var(--chakra-colors-blackAlpha-600)',
          boxShadow: '0 0 16px var(--chakra-colors-primary-50),inset 0 0 4px var(--chakra-colors-primary-600)',
          borderRadius:
            props.placement?.parent.style.borderRadius == '0px' ? '2px' : props.placement?.parent.style.borderRadius,
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'none',
          zIndex: 4
        }}
      ></div>
      <div
        className='chrome-indicator-placement'
        style={{
          display: props.placement?.type == 'inside' ? 'none' : 'flex',
          opacity: conditions.placement && conditions.placed ? 0.99 : 0,
          transition: 'opacity 0.2s',
          ...getRectStyle(props.placement && getPlacementEdge(props.placement)),
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: props.placement?.isHorizontal ? 'column' : 'row',
          filter:
            'drop-shadow(0 0 1px var(--chakra-colors-primary-50)) drop-shadow(0 0 1px var(--chakra-colors-primary-50)) ',
          position: 'absolute',
          pointerEvents: 'none',
          borderTop: props.placement?.isHorizontal ? null : '2px solid var(--chakra-colors-primary-500)',
          borderLeft: props.placement?.isHorizontal ? '2px solid var(--chakra-colors-primary-500)' : null,
          marginTop: props.placement?.isHorizontal ? 0 : '-1px',
          marginLeft: props.placement?.isHorizontal ? '-1px' : 0,
          color: 'var(--chakra-colors-primary-500)',
          zIndex: 5
        }}
      >
        <Icon
          transform={
            props.placement?.isHorizontal
              ? ' translateX(-1px) translateY(-2px)  rotate(180deg)'
              : ' translateX(-2px) translateY(-1px) rotateZ(90deg)'
          }
        >
          <path d={mdiTriangle} />
        </Icon>
        <Icon
          transform={
            props.placement?.isHorizontal
              ? ' translateX(-1px) translateY(2px) rotate(0)'
              : ' translateX(2px) translateY(-1px) rotateZ(-90deg)'
          }
        >
          <path d={mdiTriangle} />
        </Icon>
      </div>
      {placementToolbar}
    </>
  )
}
