import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { mdiClose, mdiPencilOutline } from '@mdi/js'
import type { VersionModel } from '@sitecore-feaas/sdk'
import { ChangeEvent, useState } from 'react'
import EditLink from '../../EditLink.js'

export default function VersionName({ name, onChange }: { name: string; onChange: (name: string) => void }) {
  return (
    <Popover placement='bottom-start' isLazy={true}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Box display='inline-flex' maxWidth='100%'>
              <Text as='span' color='blackAlpha.500' ml='2px'>
                Version:&nbsp;
              </Text>
              <Tooltip label='Rename'>
                <Box>
                  <EditLink href='#'>
                    <Text as='span' color='blackAlpha.800' fontWeight={600}>
                      {name}
                    </Text>
                  </EditLink>
                </Box>
              </Tooltip>
            </Box>
          </PopoverTrigger>
          <PopoverContent>
            <VersionNameDialog name={name} onClose={onClose} onChange={onChange} />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export function VersionNameDialog({
  name,
  onChange,
  onClose
}: {
  name: VersionModel['name']
  onChange: (name: string) => void
  onClose: () => void
}) {
  const [currentName, setCurrentName] = useState(name)

  return (
    <VStack alignItems={'stretch'} p={4} spacing={4}>
      <HStack alignItems={'center'}>
        <Icon zIndex={1} boxSize='icon-lg'>
          <path d={mdiPencilOutline} />
        </Icon>
        <Text fontWeight={600}>Rename version</Text>
      </HStack>

      <InputGroup>
        <Input
          placeholder='Version name'
          w='320px'
          value={currentName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrentName(e.target.value)}
        />
        {name && (
          <InputRightElement>
            <Icon cursor={'pointer'} onClick={() => setCurrentName('')}>
              <path d={mdiClose} />
            </Icon>
          </InputRightElement>
        )}
      </InputGroup>

      <HStack spacing={2} justifyContent='flex-end'>
        <Button variant='subtle' colorScheme={'purple'} onClick={onClose}>
          Discard
        </Button>
        <Button
          variant='primary'
          isDisabled={!currentName.trim()}
          onClick={() => {
            onChange(currentName)
            onClose()
          }}
        >
          Save
        </Button>
      </HStack>
    </VStack>
  )
}
